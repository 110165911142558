import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { suppliersApi } from '@src/store/services/suppliers-service'
import { FC, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'

type SupplierCategoryCreateProps = {
  onClose: () => void
}

export const SupplierCategoryCreate: FC<SupplierCategoryCreateProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const { data, isFetching: isLoadingGetOne } = suppliersApi.useGetOneSupplierCategoryQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = suppliersApi.useCreateSupplierCategoryMutation()
  const [edit, { isLoading: isLoadingEdit }] = suppliersApi.useEditSupplierCategoryMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name || '',
  }), [data])

  const onSubmit = useCallback(async (values: any, { resetForm }: any) => {
    let result

    if (params.id) {
      result = await edit({
        id: params.id,
        ...values,
      })
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      resetForm()
      onClose()
    }
  }, [create, edit, onClose, params])

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <Row gutter={[8, 0]}>
              <Col span={24}>
                <FieldFormik name="name" placeholder={t('supplierCategories.name')!} />
              </Col>
            </Row>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoadingCreate || isLoadingEdit}
            >
              {params.id ? t('supplierCategories.save') : t('supplierCategories.submit')}
            </Button>
          </Form>
        </Formik>
      </Preloader>
    </div>
  )
}
