import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { carsApi } from '@src/store/services/cars-service'
import { ColumnProps } from 'antd/lib/table'
import { AgreementInvoicesType } from '@src/types/agreementInvoices'

export const useEngineHoursTableHook = (id: number) => {
  const { t } = useTranslation()
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    filters: {
      car_id: {
        $eq: id,
      },
    },
  })
  const { data, isLoading } = carsApi.useGetCarEngineLogsQuery(paramsFilters)

  const columns = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementsCreate.customer'),
      dataIndex: 'agreement_order',
      key: 'customer',
      render: (agreementOrder) => agreementOrder.agreement.customer.name,
    },
    {
      title: t('agreementsCreate.engineHoursStart'),
      dataIndex: 'engine_hours_start',
      key: 'engine_hours_start',
    },
    {
      title: t('agreementsCreate.engineHoursEnd'),
      dataIndex: 'engine_hours_end',
      key: 'engine_hours_end',
    },
    {
      title: t('agreementsCreate.dateStart'),
      dataIndex: 'agreement_order',
      key: 'date_start',
      render: (agreementOrder) => agreementOrder.date_start,
    },
    {
      title: t('agreementsCreate.dateEnd'),
      dataIndex: 'agreement_order',
      key: 'date_end',
      render: (agreementOrder) => agreementOrder.date_end,
    },
  ], [t])

  return {
    columns,
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
  }
}
