import { FC, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { ColorPicker } from 'antd'
import { ReactComponent as SuffixIcon } from '@src/assets/suffix_icon.svg'
import { Color } from 'antd/es/color-picker'
import { getObjectValue } from '@src/lib/utils'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import cn from 'classnames'

type ColorPickerProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  label?: string
  onChangeComplete?: (value: Color) => void
}

export const ColorPickerFormik: FC<ColorPickerProps> = ({
  name, placeholder, disabled, label, onChangeComplete,
}) => {
  const { errors, touched, values } = useFormikContext<any>()

  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => error && isTouched, [error, isTouched])
  const [open, setOpen] = useState(false)

  return (
    <div className={style.wrapper}>
      <label className={cn(style.label)}>
        <div className={cn(style.labelText)}>
          {label || placeholder}
        </div>
        <ColorPicker
          defaultValue={value || '#3487E8'}
          open={open}
          onOpenChange={setOpen}
          onChangeComplete={onChangeComplete}
          size="large"
          style={{ width: '100%' }}
          disabled={disabled}
          className={cn({ [style.errorBorder]: isError })}
          showText={() => (
            <SuffixIcon />
          )}
        />
      </label>
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
