import { FC, useCallback, useMemo, ReactElement, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { Select, Spin, Tag } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import cn from 'classnames'

const { Option } = Select

type SelectFormikProps = {
  name: string
  options: Array<{ value: string | number, label: any }>
  placeholder?: string
  label?: string
  disabled?: boolean
  showSearch?: boolean
  required?: boolean
  onChange?: (value: string | number | Array<string | number>, current: any) => void
  onSearch?: (value: string | number) => void
  isMultiple?: boolean
  dropdownRender?: (menu: ReactElement) => ReactElement
  width?: string
  suffixTag?: string
  isLoading?: boolean
}

export const SelectFormik: FC<SelectFormikProps> = ({
  name,
  options,
  showSearch = true,
  placeholder,
  disabled,
  required,
  width,
  label,
  suffixTag,
  isLoading,
  isMultiple,
  onChange,
  onSearch,
  dropdownRender,
}) => {
  const { setFieldValue } = useFormikContext<any>()
  const [field, meta] = useField(name)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const isError = useMemo(() => meta.error && meta.touched, [meta])

  const handleChange = useCallback((value: any, current: any) => {
    setFieldValue(name, value)

    if (onChange) onChange(value, current)
  }, [name, onChange, setFieldValue])

  return (
    <div className={style.wrapper} style={{ width }}>
      <label className={cn(style.label)}>
        <div className={cn(style.labelText)}>
          {label || placeholder}
          {required && <span>*</span>}
        </div>
        <Select
          showSearch={showSearch}
          onSearch={onSearch}
          style={{ width: '100%' }}
          optionFilterProp="children"
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          value={isMultiple ? field.value || [] : field.value || null}
          size="large"
          className={cn({ [style.errorBorder]: isError })}
          mode={isMultiple ? 'multiple' : undefined}
          dropdownRender={dropdownRender}
          onDropdownVisibleChange={(open) => {
            setIsDropdownOpen(open)
          }}
          suffixIcon={
            (
              <div className={cn(style.suffixContainer)}>
                {isLoading ? (
                  <Spin size="small" className={style.spinner} />
                ) : (
                  suffixTag && (
                    <Tag color="blue" className={cn(style.tagStyle)}>
                      {suffixTag}
                    </Tag>
                  )
                )}
                {!disabled && (
                  isDropdownOpen ? (
                    <UpCircleOutlined style={{ fontSize: '13.33px', color: '#22262B' }} />
                  ) : (
                    <DownCircleOutlined style={{ fontSize: '13.33px', color: '#22262B' }} />
                  )
                )}
              </div>
            )
          }
        >
          {options.map((option) => (
            <Option
              {...option}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      </label>
      {isError && <div className={style.error}>{meta.error}</div>}
    </div>
  )
}
