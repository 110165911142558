import { FC, useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { DatePicker } from 'antd'
import { getObjectValue } from '@src/lib/utils'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import { ReactComponent as CalendarIcon } from '@src/assets/calendar_icon.svg'
import cn from 'classnames'
import { Moment } from 'moment'

type DatePickerFormikProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  label?: string
  showTime?: boolean
  format?: string
  disabledDate?: any
  onChange?: (e: Moment) => void
}

export const DatePickerFormik: FC<DatePickerFormikProps> = ({
  name, placeholder, disabled, showTime, format, disabledDate, onChange, label,
}) => {
  const { setFieldValue, setFieldTouched, errors, touched, values } = useFormikContext<any>()

  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => !isFocused && error && isTouched, [isFocused, error, isTouched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    setFieldTouched(name, true)
  }, [name, setFieldTouched])

  const handleChange = useCallback((vt: any) => {
    setFieldValue(name, vt)
    onChange?.(vt)
  }, [name, onChange, setFieldValue])

  return (
    <div className={style.wrapper}>
      <label className={cn(style.label)}>
        <div className={cn(style.labelText)}>
          {label || placeholder}
        </div>
        <DatePicker
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          size="large"
          placeholder={placeholder}
          value={value}
          style={{ width: '100%' }}
          format={format}
          disabled={disabled}
          showTime={showTime}
          disabledDate={disabledDate}
          className={cn({ [style.errorBorder]: isError })}
          suffixIcon={<CalendarIcon color="#22262B" />}
        />
      </label>
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
