import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { debtsApi } from '@src/store/services/debts-service'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { DebtsType } from '@src/types/debts'
import { ColumnProps } from 'antd/lib/table'
import { Tag } from 'antd'
import { TableFiltersType } from '@src/types/tableFilters'
import cn from 'classnames'
import { companiesApi } from '@src/store/services/companies-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { PageTable } from '@src/components/Table/PageTable'
import { Confirm } from '@src/components/Confirm/Confirm'
import { ReactComponent as SendMailIcon } from '@src/assets/send-mail_icon.svg'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { isSelectionText } from '@src/lib/utils'
import style from './debts.module.scss'

type DebtsActionsProps = {
  record: DebtsType
}

export const DebtsActions: FC<DebtsActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [remove] = debtsApi.useRemoveMutation()

  const handleDelete = () => {
    remove(record.id)
  }

  return (
    <div className={cn('table_actions')}>
      <button
        type="button"
        onClick={() => {
          dispatch(setSendEmail({
            type: SendEmailType.Debts,
            companyId: record.company_id,
            id: record.id,
          }))
        }}
        className={cn('table_actions_item')}
      >
        <SendMailIcon />
      </button>
      <Confirm
        title={t('deleteModal.deleteConfirmationTitle')}
        content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.debts') })}
        okText={t('deleteModal.delete')}
        cancelText={t('deleteModal.cancel')}
        onOk={handleDelete}
        className={cn('table_actions_item', 'table_actions_item__delete')}
      >
        <DeleteIcon />
      </Confirm>
    </div>
  )
}

export const Debts = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery()
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : undefined,
  })
  const { data, isLoading } = debtsApi.useGetListQuery(paramsFilters)

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCellMailHandler = useCallback((record: DebtsType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: SendEmailType.Debts,
          companyId: record.company_id,
          id: record.id,
        }))
      }
    },
  }), [dispatch])

  const onCell = useCallback((record: DebtsType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/debts/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<DebtsType>[]>(() => [
    {
      title: t('invoices.columns.company'),
      dataIndex: 'company',
      key: 'company',
      render: (company) => company.full_name,
      onCell,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag className={style.status} color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '76px',
      fixed: 'right',
      render: (_, record) => (
        <DebtsActions record={record} />
      ),
    },
  ], [onCell, onCellMailHandler, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'select',
        dataIndex: 'company_id',
        placeholder: t('agreements.columns.customer'),
        width: '150px',
        dropdownWidth: '250px',
        options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
        showSearch: true,
        operator: '$eq',
      },
      {
        type: 'select',
        dataIndex: 'isSentCustomer',
        placeholder: t('debts.columns.sentCustomer'),
        width: '129px',
        operator: '$eq',
        options: [
          {
            value: '0',
            label: t('sendStatus.0'),
          },
          {
            value: '1',
            label: t('sendStatus.1'),
          },
        ],
      },
    ],
  ], [customerNamesList, t])

  return (
    <div>
      <PageTable
        title={t('debts.title')}
        createLink="/debts/create"
        filtersV2
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
        }}
      />
    </div>
  )
}
