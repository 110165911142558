import { FC, ReactNode, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { ImagesPreview } from '@src/components/ImagesPreview/ImagesPreview'
import { AppDispatch } from '@src/store/store'
import { carsApi } from '@src/store/services/cars-service'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useCarsViewContentListHook } from './CarsViewContentList.hook'

export type Images = {
  id: number
  image_s3: string
  thumb_s3: string
  order: number
}

type CarViewContentProps = {
  activeTabKey: string
  serialNumber: string
  id: number
  images: Record<string, Images[]>
}

export const CarViewContent: FC<CarViewContentProps> = ({ activeTabKey, serialNumber, images, id }) => {
  const contentList = useCarsViewContentListHook(serialNumber, id)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [deleteImage] = carsApi.useDeleteImageMutation()

  const onImageDelete = useCallback(
    (imageId?: number) => {
      const { confirm } = Modal

      return new Promise<void>((resolve, reject) => {
        if (!imageId) {
          reject()
          return
        }

        confirm({
          title: t('confirmDeleteFileTitle'),
          onOk: async () => {
            await deleteImage(imageId)
            dispatch(agreementsApi.util.invalidateTags(['Agreements']))
            resolve()
          },
          onCancel: () => {
            reject()
          },
        })
      })
    },
    [deleteImage, dispatch, t],
  )

  const contentListRender = useMemo<Record<string, ReactNode>>(
    () => ({
      ...contentList,
      gallery: <ImagesPreview images={images} onImageDelete={onImageDelete} />,
    }),
    [images, contentList, onImageDelete],
  )

  return <div>{contentListRender[activeTabKey]}</div>
}
