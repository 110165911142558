import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { DebtsType } from '@src/types/debts'
import { GetItemsType } from '@src/api/api'
import { formatFilters } from '@src/lib/formatFilters'

export const debtsApi = createApi({
  reducerPath: 'debtsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Debts'],
  endpoints: (build) => ({
    getList: build.query<GetItemsType<DebtsType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/debts?${formatFilters(params)}`,
      }),
      providesTags: () => ['Debts'],
    }),
    getOne: build.query<DebtsType, string>({
      query: (id) => ({
        url: `/debts/${id}`,
      }),
      providesTags: () => ['Debts'],
    }),
    create: build.mutation<DebtsType, any>({
      query: (params) => ({
        url: '/debts',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Debts'],
    }),
    update: build.mutation<DebtsType, {id: number, sum: number, company_id: number}>({
      query: (params) => ({
        url: `/debts/${params.id}`,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['Debts'],
    }),
    sendEmail: build.mutation<void, {id: number, email: string}>({
      query: (params) => ({
        url: `/debts/${params.id}/customer-send`,
        method: 'GET',
        params,
      }),
      invalidatesTags: ['Debts'],
    }),
    remove: build.mutation<void, number>({
      query: (id) => ({
        url: `/debts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Debts'],
    }),
  }),
})
