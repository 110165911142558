import { FC } from 'react'
import { Pagination } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { ReactComponent as DotsIcon } from '@src/assets/dots_icon.svg'
import style from './paginationFormik.module.scss'

type PaginationFormikProps = {
  total: number;
  current: number;
  pageSize: number;
  onChange: (page: number) => void;
}

export const PaginationFormik: FC<PaginationFormikProps> = ({
  total,
  current,
  pageSize,
  onChange,
}) => {
  const customItemRender = (_page: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="custom-pagination-arrow prev">
          <LeftCircleOutlined style={{ width: '40px', height: '40px', fontSize: '20px' }} />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="custom-pagination-arrow next">
          <RightCircleOutlined style={{ width: '40px', height: '40px', fontSize: '20px' }} />
        </div>
      )
    }
    if (type === 'jump-next' || type === 'jump-prev') {
      return (
        <div className="custom-pagination-dot">
          <DotsIcon />
        </div>
      )
    }
    return originalElement
  }

  return (
    <div className={style.customPagination}>
      <Pagination
        itemRender={customItemRender}
        total={total}
        current={current}
        pageSize={pageSize}
        onChange={onChange}
        showSizeChanger={false}
        showQuickJumper={false}
      />
    </div>
  )
}
