import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Col, Drawer, Row } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { CarCategoriesCreate } from './CarCategoriesCreate'
import { CarsTable } from '../../components/pages/cars/CarsTable'
import style from './carsCategory.module.scss'

export const CarCategoriesView = () => {
  const { t } = useTranslation()
  const pageQuery = useQuery('page')
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const { data, isFetching: isLoadingGetOne } = carCategoriesApi.useGetOneCarCategoryQuery(+params?.id!, { skip: !params?.id })

  const { columns, data: cars, paramsFilters, setParamsFilters, isLoading } = CarsTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        car_category_id: {
          $eq: params.id,
        },
      },
    },
  })

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  return (
    <div className={style.categoriesContainer}>
      {data && (
        <ViewContentHeader
          onBackClick={() => history.push('/car-categories')}
        >
          {data.name}
        </ViewContentHeader>
      )}
      <span className={style.title}>{t('carCategories.info')}</span>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <Card
            loading={isLoadingGetOne}
            style={{
              borderRadius: '16px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('carCategories.columns.name')}:`}</span>
                  <span className={style.cardTextResult}>{data?.name}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('carCategories.columns.carsNumber')}:`}</span>
                  <span className={style.cardTextResult}>{cars?.meta.totalCount}</span>
                </div>
              </div>
              <Button
                type="primary"
                icon={<EditIcon />}
                size="large"
                className={style.button}
                onClick={openDrawer}
              >
                {t('carCategories.edit')}
              </Button>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <PageTable
            isTableOnly
            paramsFilters={paramsFilters}
            setParamsFilters={setParamsFilters}
            tableData={{
              ...cars,
              isLoading,
              columns,
            }}
          />
        </Col>
        <Drawer
          title={t('carCategories.editTitle')}
          open={isDrawerVisible}
          onClose={closeDrawer}
          width={554}
        >
          <CarCategoriesCreate
            record={data}
            onClose={closeDrawer}
          />
        </Drawer>
      </Row>
    </div>
  )
}
