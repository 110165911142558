import { useState } from 'react'
import { Collapse } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { Images } from './Image'
import styles from './ImagesPreview.module.scss'

export type Image = {
  id: number
  image_s3: string
  thumb_s3: string
  order: number
};

type ImagesPreviewProps = {
  images: Record<string, Image[]>
  onImageDelete?: (imageId?: number) => Promise<void>
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export const ImagesPreview = ({ images, onImageDelete }: ImagesPreviewProps) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const handlePanelChange = (keys: string | string[]) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys])
  }

  const sortedDates = Object.keys(images).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())

  return (
    <Collapse
      accordion
      activeKey={activeKeys}
      onChange={handlePanelChange}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        isActive ? (
          <UpCircleOutlined style={{ fontSize: '16px', color: '#22262B' }} />
        ) : (
          <DownCircleOutlined style={{ fontSize: '16px', color: '#22262B' }} />
        )
      )}
    >
      {sortedDates.map((dateKey) => (
        <Collapse.Panel
          header={<div className={styles.customCollapseHeader}><span>{formatDate(dateKey)}</span></div>}
          key={dateKey}
        >
          <Images
            onImageDelete={onImageDelete}
            imageList={images[dateKey]}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}
