import { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Button, Card, Col, Divider, Row } from 'antd'

import { Preloader } from '@src/components/Preloader/Preloader'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { agreementsApi } from '@src/store/services/agreements-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { companiesApi } from '@src/store/services/companies-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { AgreementInvoicesDocument } from '@src/components/pages/agreementInvoices/AgreementInvoicesDocument'
import * as Yup from 'yup'
import moment, { Moment } from 'moment'
import Holidays from 'date-holidays'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { formatPrice } from '@src/lib/formatPrice'
import cn from 'classnames'
import style from './agreementInvoicesEdit.module.scss'

function getLithuanianHolidays(year: number) {
  const hd = new Holidays('LT')
  const holidays = hd.getHolidays(year)

  // Преобразуем список праздников в массив строковых дат в формате 'YYYY-MM-DD'
  const holidayDates = holidays.map((holiday) => holiday.date)
  return holidayDates
}

function calculateLeaseTerm(startDate: string, endDate: string) {
  let leaseTerm = 0
  const currentDate = moment(startDate)
  const finalDate = moment(endDate).add(1, 'day') // добавляем 1 день к дате окончания

  const holidayDates = getLithuanianHolidays(currentDate.year())

  while (currentDate.isBefore(finalDate)) {
    const formattedDate = currentDate.format('YYYY-MM-DD')

    // Проверяем, является ли день будним и отсутствует ли в списке праздничных дней
    if (currentDate.isoWeekday() < 6 && !holidayDates.includes(formattedDate)) {
      leaseTerm += 1
    }

    currentDate.add(1, 'day') // Переходим к следующему дню
  }

  return leaseTerm
}

export const AgreementInvoicesEdit = () => {
  const { t } = useTranslation()
  const formRef = useRef<any>()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const { data: info, isLoading: isLoadingGetOne } = agreementsApi.useGetOneAgreementInvoiceV2Query(+params.id)
  const [editAgreementInvoices, { isLoading }] = agreementsApi.useEditAgreementInvoiceV2Mutation()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      bill_number: Yup.string().required(t('form.errors.required') ?? ''),
      pay_by: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: info?.id ?? '',
    text: info?.text ?? '',
    bill_number: info?.bill_number ?? '',
    price: info?.price ?? '',
    date_created: info?.date_created ? moment(info?.date_created) : '',
    pay_by: info?.pay_by ? moment(info?.pay_by) : '',
    date_start: info?.date_start ? moment(info?.date_start) : '',
    date_end: info?.date_end ? moment(info?.date_end) : '',
    company_id: info?.company_id ?? '',
    owner_id: info?.owner_id ?? '',
    orderId: info?.order_id ?? '',
    type: info?.type ?? '',
    unit: info?.unit ?? '',
    quantity: info?.quantity ?? '',
    unit_price: info?.unit_price ?? '',
    vat_calculated: info?.vat_calculated ?? false,
    unit_text: info?.unit_text ?? '',
  }), [info])

  const onSubmit = useCallback(async (data: any) => {
    const result = await editAgreementInvoices({
      id: +params.id,
      body: {
        ...data,
        pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
        date_created: data.date_created ? data.date_created.format('YYYY-MM-DD') : '',
        date_start: data.date_start ? data.date_start.format('YYYY-MM-DD') : '',
        date_end: data.date_end ? data.date_end.format('YYYY-MM-DD') : '',
      },
    })

    if ('data' in result) {
      if (formRef.current.values.price && formRef.current.values.bill_number && info) {
        dispatch(setSendEmail({
          type: SendEmailType.AgreementInvoicesV2,
          companyId: +info.company_id,
          id: +params.id,
        }))
      }

      history.goBack()
    }
  }, [dispatch, editAgreementInvoices, history, info, params.id])

  const handleDateChange = (dateStart: Moment, dateEnd: Moment) => {
    const invoicePeriodDays = moment(dateEnd).diff(dateStart, 'days') + 1

    const daysInMonth = moment(dateEnd).endOf('month').date()
    const isFullMonth = invoicePeriodDays === daysInMonth
    const newQuantity = calculateLeaseTerm(dateStart as any, dateEnd as any)

    if (formRef.current.values.unit === 'months' && !isFullMonth) {
      formRef.current.setFieldValue('unit', 'days')
    }

    if (formRef.current.values.unit === 'months' && isFullMonth) {
      formRef.current.setFieldValue('quantity', 1)
      const price = (+formRef.current.values.unit_price * newQuantity).toFixed(2)
      formRef.current.setFieldValue('price', price)
      formRef.current.setFieldValue('unit_price', price)
    } else {
      formRef.current.setFieldValue('quantity', newQuantity)
      formRef.current.setFieldValue('price', (+formRef.current.values.unit_price * newQuantity).toFixed(2))
    }
  }

  return (
    <div>
      <h1 className="page_title">{t('agreementInvoicesEdit.titleEdit')}</h1>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={formRef}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => {
            const pvm = values.vat_calculated ? ((+values.price || 0) * 0.21).toFixed(2) : 0

            return (
              <Row gutter={[24, 24]}>
                <Col xl={24} xxl={12}>
                  <Form className="not-card">
                    <Card>
                      <Row gutter={[20, 0]}>
                        <Col xs={24} sm={12}>
                          <SelectFormik
                            name="company_id"
                            placeholder={t('transportationBillsEdit.company') ?? ''}
                            options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                          />
                        </Col>
                        <Col xs={24} sm={12}>
                          <FieldFormik
                            name="bill_number"
                            placeholder={t('agreementInvoicesEdit.number') ?? ''}
                          />
                        </Col>
                        <Col xs={24} sm={12}>
                          <DatePickerFormik name="date_created" placeholder={t('agreementInvoicesEdit.dateCreated') ?? ''} />
                        </Col>
                        <Col xs={24} sm={12}>
                          <DatePickerFormik name="pay_by" placeholder={t('agreementInvoicesEdit.payBy') ?? ''} />
                        </Col>
                      </Row>
                    </Card>
                    <Card style={{ marginTop: 8 }}>
                      <Row gutter={[20, 0]}>
                        <Col xs={24} sm={12}>
                          <DatePickerFormik
                            name="date_start"
                            placeholder={t('agreementInvoices.columns.dateStart') ?? ''}
                            onChange={(e) => handleDateChange(e, values.date_end as any)}
                          />
                        </Col>
                        <Col xs={24} sm={12}>
                          <DatePickerFormik
                            name="date_end"
                            placeholder={t('agreementInvoices.columns.dateEnd') ?? ''}
                            onChange={(e) => handleDateChange(values.date_start as any, e)}
                          />
                        </Col>
                        <Col xs={24} md={8}>
                          <SelectFormik
                            name="unit"
                            options={[
                              {
                                value: 'months',
                                label: t('agreementsCreate.paymentTerms.months'),
                              },
                              {
                                value: 'days',
                                label: t('agreementsCreate.paymentTerms.days'),
                              },
                              {
                                value: 'hours',
                                label: t('agreementsCreate.paymentTerms.hours'),
                              },
                            ]}
                            placeholder={t('agreementsCreate.paymentTerm')}
                          />
                        </Col>
                        <Col xs={24} md={8}>
                          <FieldFormik
                            name="quantity"
                            placeholder={t('transportationBillsEdit.quantity')}
                            onChange={(e: any) => {
                              setFieldValue('price', +values.unit_price * +e.target.value)
                            }}
                          />
                        </Col>
                        <Col xs={24} md={8}>
                          <FieldFormik
                            name="unit_price"
                            type="number"
                            placeholder={t('transportationBillsEdit.unitPrice')}
                            onChange={(e: any) => {
                              setFieldValue('price', +values.quantity * +e.target.value)
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <FieldFormik
                            name="unit_text"
                            placeholder={t('agreementInvoicesEdit.unitText') ?? ''}
                          />
                        </Col>
                        <Col span={24}>
                          <SwitchFormik name="vat_calculated" placeholder={t('agreementInvoicesEdit.vatCalculated')} />
                        </Col>
                      </Row>
                      <Divider />
                      <div className={style.suma}>
                        <div>{t('agreementInvoicesEdit.sum')}</div>
                        <div className={style.value}>{formatPrice(+values.price)}</div>
                      </div>
                      <div className={style.suma}>
                        <div>
                          {t('agreementInvoicesEdit.pvm')}
                          {values.vat_calculated ? ' (21%):' : ':'}
                        </div>
                        <div className={style.value}>{formatPrice(+pvm)}</div>
                      </div>
                      <div className={cn(style.suma, style.sumaTotal)}>
                        <div>{t('agreementInvoicesEdit.totalSum')}</div>
                        <div>{formatPrice(+(+pvm + +values.price).toFixed(2))}</div>
                      </div>
                      <br />
                      <FieldFormik
                        name="text"
                        placeholder={t('agreementInvoicesEdit.text') ?? ''}
                      />

                      <Button htmlType="submit" type="primary" loading={isLoading}>
                        {t('agreementInvoicesEdit.submit')}
                      </Button>
                      <NavLink
                        to={`/agreement-orders/edit/${info?.order_id}`}
                        className={style.link}
                      >
                        {t('agreementInvoicesEdit.openOrder')}
                      </NavLink>
                    </Card>
                  </Form>
                </Col>
                <Col xl={24} xxl={12}>
                  <Card>
                    <AgreementInvoicesDocument />
                  </Card>
                </Col>
              </Row>
            )
          }}
        </Formik>
      </Preloader>
    </div>
  )
}
