import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableFiltersType } from '@src/types/tableFilters'
import { CarStatus } from '@src/types/car'
import useQuery from '@src/hooks/useQuery'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'
import { CarsTable } from '@src/components/pages/cars/CarsTable'
import { Drawer } from 'antd'
import { CarsCreate } from '../CarsCreate/CarsCreate'

export const Cars = () => {
  const { t } = useTranslation()
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [exportCars, { isLoading: isLoadingExport }] = carsApi.useExportCarsMutation()

  const { columns, data, paramsFilters, setParamsFilters, isLoading } = CarsTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : {},
    },
  })

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'select',
        dataIndex: 'make',
        placeholder: t('cars.columns.make'),
        operator: '$eq',
        dropdownWidth: '200px',
        width: '78px',
        options: makesList?.map((item) => ({ value: item, label: item })) || [],
        showSearch: true,
      },
      {
        type: 'select',
        dataIndex: 'model',
        placeholder: t('cars.columns.model'),
        operator: '$eq',
        dropdownWidth: '200px',
        width: '87px',
        options: modelsList?.map((item) => ({ value: item, label: item })) || [],
        showSearch: true,
      },
      {
        type: 'input',
        dataIndex: 'serial_number',
        placeholder: t('cars.columns.serialNumber'),
        operator: '$contains',
        width: '111px',
      },
      {
        type: 'select',
        dataIndex: 'owner_id',
        placeholder: t('cars.columns.owner'),
        dropdownWidth: '200px',
        operator: '$eq',
        width: '100px',
        options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
      },
    ],
    [
      {
        type: 'select',
        dataIndex: 'status',
        placeholder: t('cars.columns.status'),
        operator: '$eq',
        dropdownWidth: '150px',
        width: '85px',
        options: [
          {
            value: CarStatus.Available,
            label: t('cars.columns.statusFree'),
          },
          {
            value: CarStatus.InRent,
            label: t('cars.columns.statusRent'),
          },
          {
            value: CarStatus.Service,
            label: t('cars.columns.statusRepair'),
          },
          {
            value: CarStatus.Sold,
            label: t('cars.columns.statusSold'),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'from_service',
        placeholder: t('cars.columns.fromService'),
        operator: '$eq',
        width: '119px',
        options: [
          {
            value: '0',
            label: t('common.false'),
          },
          {
            value: '1',
            label: t('common.true'),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'paid_once',
        placeholder: t('cars.columns.paidOnce'),
        operator: '$eq',
        width: '165px',
        options: [
          {
            value: '0',
            label: t('common.false'),
          },
          {
            value: '1',
            label: t('common.true'),
          },
        ],
      },
    ],
  ], [ownersSelect, makesList, modelsList, t])

  return (
    <div>
      <PageTable
        title={t('cars.title')!}
        // createLink="/cars/create"
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onExport={exportCars}
        filtersV2
        onCreate={openDrawer}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={t('carCreate.title')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CarsCreate
          // record={drawerConfig.record}
          onClose={closeDrawer}
        />
      </Drawer>
    </div>
  )
}
