import { TransportationCompanyType } from '@src/types/transportation'

export type InvoicesType = {
  id: number
  pay_by: string
  sum: string
  is_credit: number
  bill_number: string
  date: string
  status: InvoicesStatus
  company_id: number
  services: string
  company: TransportationCompanyType
  vat_calculated: number
  with_pvm: boolean
  type: any
  comment: string
}

export enum InvoicesTypeEnum {
  tax = 'tax',
  credit = 'credit',
  advance = 'advance',
  proforma= 'proforma',
}

export enum InvoicesUnitEnum {
  unit = 'unit',
  hour = 'hour',
  day = 'day',
  month= 'month',
}

export enum InvoicesStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}
