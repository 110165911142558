import { FC } from 'react'
import { Switch } from 'antd'
import { useField, useFormikContext } from 'formik'
import style from './switchFormik.module.scss'

type SwitchFormikProps = {
  name: string
  placeholder: string
}

export const SwitchFormik: FC<SwitchFormikProps> = ({ name, placeholder }) => {
  const {
    setFieldValue,
  } = useFormikContext<any>()
  const [field] = useField(name)

  const handleChange = (checked: boolean) => {
    setFieldValue(name, checked ? 1 : 0)
  }

  return (
    <div className={style.wrapper}>
      <Switch onChange={handleChange} checked={field.value} />
      <span className={style.placeholder}>{placeholder}</span>
    </div>
  )
}
