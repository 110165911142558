import { FC, ReactNode, useMemo } from 'react'
import { CarImages } from '@src/types/car'
import { CarHistory } from '@src/Pages/Test/CarsMechanicView/CarHistory/CarHistory'
import { ImagesPreview } from '@src/components/ImagesPreview/ImagesPreview'

type CarViewContentProps = {
  activeTabKey: string
  carId: number
  images: CarImages
}

export const CarsMechanicViewContent: FC<CarViewContentProps> = ({ activeTabKey, carId, images }) => {
  const contentListRender = useMemo<Record<string, ReactNode>>(() => ({
    history: <CarHistory carId={carId} />,
    gallery: <ImagesPreview images={images} />,
  }), [images, carId])

  return contentListRender[activeTabKey]
}
