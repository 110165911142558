import { FC, startTransition, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PageTable } from '@src/components/Table/PageTable'
import { TableFiltersType } from '@src/types/tableFilters'
import { agreementsApi } from '@src/store/services/agreements-service'
import useQuery from '@src/hooks/useQuery'
import { carsApi } from '@src/store/services/cars-service'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { CloseAgreementOrderForm } from '@src/components/pages/agreements/CloseAgreementOrderForm'
import { addQuery } from '@src/lib/route'
import { useAgreementOrderTableHook } from '@src/components/pages/agreements/AgreemetOrderTable.hook'

type AgreementOrderProps = {
  isTableOnly?: boolean;
  companyId?: number;
  companyType?: CompanyType;
}

export const AgreementOrders: FC<AgreementOrderProps> = ({ isTableOnly, companyId, companyType }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const { data: customerSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const [exportAgreementOrder, { isLoading: isLoadingExport }] = agreementsApi.useExportAgreementOrderMutation()

  const { columns, paramsFilters, setParamsFilters, isLoading, data, closeAgreementId, setCloseAgreementId } = useAgreementOrderTableHook({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: {
        ...(companyId && companyType === CompanyType.Customer
          ? { agreement: { customer_id: { $eq: companyId } } }
          : {}),
        ...(companyId && companyType === CompanyType.Owner
          ? { agreement: { owner_id: { $eq: companyId } } }
          : {}),
        ...(filtersQuery ? JSON.parse(filtersQuery) : {}),
      },
    },
  })

  const handleFilterChange = useCallback((filters: any) => {
    startTransition(() => {
      setParamsFilters(filters)
    })
  }, [setParamsFilters])

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'car.make',
      operator: '$eq',
      placeholder: t('agreements.columns.make'),
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'car.model',
      operator: '$eq',
      placeholder: t('agreements.columns.model'),
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'car.serial_number',
      operator: '$contains',
      placeholder: t('agreements.columns.serialNumber'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'agreement.customer_id',
      operator: '$eq',
      placeholder: t('agreements.columns.customer'),
      width: '200px',
      options: customerSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'agreement.owner_id',
      operator: '$eq',
      placeholder: t('cars.columns.owner'),
      width: '200px',
      options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
    },
    {
      type: 'select',
      dataIndex: 'status',
      operator: '$eq',
      width: '200px',
      placeholder: t('agreements.columns.status'),
      options: [
        {
          value: 0,
          label: t('agreements.columns.statusOpen'),
        },
        {
          value: 1,
          label: t('agreements.columns.statusClosed'),
        },
      ],
    },
    {
      type: 'rangePicker',
      dataIndex: 'date_start',
      placeholder: [t('filters.dateStartFrom'), t('filters.dateStartTo')],
    },
    {
      type: 'rangePicker',
      dataIndex: 'date_end',
      placeholder: [t('filters.dateEndFrom'), t('filters.dateEndTo')],
    },

  ], [customerSelect, makesList, modelsList, ownersSelect, t])

  return (
    <div>
      <PageTable
        title={t('agreements.title')!}
        createLink="/agreement-orders/create"
        isTableOnly={isTableOnly}
        filtersList={filtersList}
        setParamsFilters={handleFilterChange}
        columnsHeight="64px"
        paramsFilters={paramsFilters}
        filtersV2
        onExport={exportAgreementOrder}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <CloseAgreementOrderForm agreementOrderId={closeAgreementId} onClose={() => setCloseAgreementId(null)} />
    </div>
  )
}
