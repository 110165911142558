import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { InvoicesStatus, InvoicesType } from '@src/types/invoices'
import { GetItemsType } from '@src/api/api'
import { formatFilters } from '@src/lib/formatFilters'

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Invoices'],
  endpoints: (build) => ({
    getList: build.query<GetItemsType<InvoicesType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/business-invoices?${formatFilters(params)}`,
      }),
      providesTags: () => ['Invoices'],
    }),
    getOne: build.query<InvoicesType, string>({
      query: (id) => ({
        url: `/business-invoices/${id}`,
      }),
      providesTags: () => ['Invoices'],
    }),
    create: build.mutation<InvoicesType, any>({
      query: (body) => ({
        url: '/business-invoices',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    update: build.mutation<InvoicesType, any>({
      query: (body) => ({
        url: `/business-invoices/${body.invoiceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    changeStatus: build.mutation<void, {id: number, status: InvoicesStatus}>({
      query: (body) => ({
        url: `/business-invoices/${body.id}/change-status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Invoices'],
    }),
    sendEmail: build.mutation<void, {id: number, email: string}>({
      query: (params) => ({
        url: `/business-invoices/${params.id}/customer-send`,
        method: 'GET',
        params,
      }),
      invalidatesTags: ['Invoices'],
    }),
    remove: build.mutation<void, number>({
      query: (id) => ({
        url: `/business-invoices/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoices'],
    }),

    getAllInvoices: build.query<any, { page: number, filters: any }>({
      query: (params) => ({
        url: '/settings/get-all-invoices',
        params: {
          page: params.page,
          ...params.filters?.filters?.reduce((acc: Record<string, any>, filter: any) => {
            // eslint-disable-next-line no-param-reassign
            acc[filter.columns] = filter.value
            return acc
          }, {}),
        },
      }),
      providesTags: () => ['Invoices'],
    }),
  }),
})
