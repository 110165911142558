export const theme = {
  token: {
    colorPrimary: 'rgb(21, 19, 13)',
    colorInfo: 'rgb(21, 19, 13)',
    controlItemBgActiveHover: 'rgb(21 19 13 / 20%)',
    controlItemBgActive: 'rgb(21 19 13 / 20%)',
  },
  components: {
    Input: {
      colorBorder: 'rgb(235,235,235)',
      colorText: 'rgb(17,17,17)',
      colorError: 'rgb(255,59,48)',
      colorTextPlaceholderLG: 'rgba(0, 0, 0, 0.5)',
      borderRadiusLG: 8,
      controlHeightLG: 40,
      lineHeightLG: 1.571,
      paddingInlineLG: 12,
      paddingBlockLG: 8,
      fontSizeLG: 14,
    },
    Select: {
      colorBorder: 'rgb(235,235,235)',
      colorTextPlaceholderLG: 'rgba(0, 0, 0, 0.5)',
      borderRadiusLG: 8,
      controlHeightLG: 40,
      borderRadiusSM: 4,
      colorError: 'rgb(255,59,48)',
      lineHeightLG: 1.571,
      fontSizeLG: 14,
      optionHeightLG: 38,
      optionFontSize: 14,
      optionPadding: '12px 16px',
      optionLineHeight: 1.571,
      optionActiveBg: '#F4F4F4',
      optionSelectedColor: '#22262B',
      optionSelectedBg: '#F4F4F4',
      multipleItemBg: '#F7F8F9',
      optionSelectedFontWeight: 400,
      colorIcon: '#22262B',
    },
    DatePicker: {
      borderRadius: 8,
      colorBorder: 'rgb(235,235,235)',
      colorText: 'rgb(17,17,17)',
      colorError: 'rgb(255,59,48)',
      colorTextPlaceholderLG: 'rgba(0, 0, 0, 0.5)',
      controlHeightLG: 42,
      lineHeightLG: 1.571,
      fontSizeLG: 14,
    },
    Switch: {
      handleSize: 18,
      trackPadding: 3,
      trackHeight: 24,
      trackMinWidth: 42,
      colorPrimary: 'rgb(57,121,245)',
      colorPrimaryHover: 'rgba(64,150,255,0.97)',
    },
    Table: {
      headerBg: '#FFFFFF',
      headerColor: '#22262B',
      fontWeightStrong: 500,
      headerBorderRadius: 16,
      cellPaddingInline: 16,
      headerSplitColor: '#FFFFFF',
    },
  },
}
