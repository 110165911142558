import {
  FC, ForwardRefExoticComponent, useCallback, useMemo, useState,
  ReactNode,
} from 'react'
import { AutoComplete, Input } from 'antd'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import style from './FieldFormik.module.scss'

type FieldFormikProps = {
  name: string
  Prefix?: ForwardRefExoticComponent<any>
  placeholder?: string
  type?: string
  required?: boolean
  styleInline?: object
  autoSize?: {
    minRows: number
    maxRows: number
  }
  addonBefore?: ReactNode
  autoFocus?: boolean
  disabled?: boolean
  label?: string
  notLable?: boolean
  readonly?: boolean
  autoComplete?: string
  onChange?: (e: any) => void
  size?: SizeType
  noMarginBottom?: boolean
  autoCompleteOptions?: string[]
}

export const FieldFormik: FC<FieldFormikProps> = ({
  Prefix,
  autoComplete,
  readonly,
  disabled,
  autoFocus,
  addonBefore,
  styleInline,
  label,
  notLable,
  placeholder,
  name,
  type = 'text',
  required,
  autoSize,
  onChange,
  size = 'large',
  noMarginBottom,
  autoCompleteOptions,
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleBlur = useCallback((e: any) => {
    setIsFocused(false)
    field.onBlur(e)
  }, [field])

  const handleChange = useCallback((e: any) => {
    if (type === 'autoComplete') {
      setFieldValue(name, e)
    } else {
      field.onChange(e)
    }

    if (onChange) {
      onChange(e)
    }
  }, [field, name, onChange, setFieldValue, type])

  const fieldComponent = useMemo(
    () => {
      switch (type) {
        case 'password':
          return (
            <Input.Password
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              size="large"
              placeholder={placeholder}
            />
          )
        case 'textarea':
          return (
            <Input.TextArea
              {...field}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              autoSize={autoSize}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              placeholder={placeholder}
            />
          )
        case 'autoComplete':
          return (
            <AutoComplete
              {...field}
              style={{ ...styleInline, borderColor: isError ? 'red' : '', width: '100%' }}
              options={autoCompleteOptions?.map((value) => ({ value }))}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              onChange={handleChange}
              size={size}
              placeholder={placeholder}
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          )
        default:
          return (
            <Input
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              onChange={handleChange}
              size={size}
              placeholder={placeholder}
            />
          )
      }
    },
    [
      Prefix, addonBefore, autoComplete, autoFocus, autoCompleteOptions,
      autoSize, disabled, field, handleBlur, handleChange,
      handleFocus, isError, readonly, styleInline, type, size, placeholder,
    ],
  )

  return (
    <div
      className={cn(style.wrapper, {
        [style.wrapperNoMargin]: noMarginBottom,
        [style.wrapperNoLable]: notLable,
      })}
    >
      {!notLable ? (
        <label className={cn(style.label)}>
          <div className={cn(style.labelText)}>
            {(label || placeholder)}
            {required && <span>*</span>}
          </div>
          {fieldComponent}
          {isError && <div className={style.error}>{meta.error}</div>}
        </label>
      ) : (
        fieldComponent
      )}
    </div>
  )
}
