import { useCallback, useMemo, useRef, useState } from 'react'
import { Button, Col, Drawer, List, Row } from 'antd'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import style from '@src/Pages/CarsCreate/carsCreate.module.scss'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { tasksApi } from '@src/store/services/tasks-service'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { standardWorkApi } from '@src/store/services/standard-work-service'
import { usersApi } from '@src/store/services/users-service'
import { detailsApi } from '@src/store/services/details-service'
import { serviceTemplatesApi } from '@src/store/services/serviceTemplates-service'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import { AddressFormik } from '@src/components/AddressFormik/AddressFormik'
import { authApi } from '@src/store/services/auth-service'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { UserRoles } from '@src/types/users'
import { CarsCreate } from '@src/Pages/CarsCreate/CarsCreate'
import { UsersCreate } from '@src/Pages/UsersCreate/UsersCreate'
import { CustomerCreate } from '@src/Pages/CustomerCreate/CustomerCreate'

export const TasksCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const formRef = useRef<any>(null)
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'worker' | 'customer' | null>(null)
  const { data: user } = authApi.useGetMeQuery()
  const { data: clientsSelect } = companiesApi.useGetSelectCompaniesQuery(
    undefined,
    { skip: ![UserRoles.Admin, UserRoles.ServiceManager].includes(user?.role!) },
  )
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery(
    {},
    { skip: ![UserRoles.Admin, UserRoles.ServiceManager].includes(user?.role!) },
  )
  const { data, isLoading: isLoadingGetOne } = tasksApi.useGetOneTaskQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = tasksApi.useCreateTaskMutation()
  const [edit, { isLoading: isLoadingEdit }] = tasksApi.useEditTaskMutation()
  const { data: workTypes } = standardWorkApi.useGetWorkTypesListForSelectQuery(
    undefined,
    { skip: ![UserRoles.Admin, UserRoles.ServiceManager].includes(user?.role!) },
  )
  const { data: workers } = usersApi.useGetWorkersForSelectQuery(
    undefined,
    { skip: ![UserRoles.Admin, UserRoles.ServiceManager].includes(user?.role!) },
  )
  const { data: details } = detailsApi.useGetDetailsListForSelectQuery()
  const { data: templates } = serviceTemplatesApi.useGetServiceTemplatesListForSelectQuery(
    undefined,
    { skip: ![UserRoles.Admin, UserRoles.ServiceManager].includes(user?.role!) },
  )
  const [triggerTemplate] = serviceTemplatesApi.useLazyGetOneServiceTemplateQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      client_id: Yup.string().required(t('form.errors.required') ?? ''),
      work_type_id: Yup.string().required(t('form.errors.required') ?? ''),
      car_id: Yup.string().required(t('form.errors.required') ?? ''),
      worker_ids: Yup.array().min(1),
      place: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: data?.id || null,
    client_id: data?.client_id || '',
    work_type_id: data?.work_type_id || '',
    name: data?.name || '',
    description: data?.description || '',
    car_id: data?.car_id || '',
    worker_ids: data?.workers.map((item) => item.id) || [],
    planned_date_start: data?.planned_date_start ? moment(data.planned_date_start) : '',
    planned_date_end: data?.planned_date_end ? moment(data.planned_date_end) : '',
    template_id: data?.template_id || '',
    place: data?.place || '',
    detailsSelectTmp: [],
    details: data?.details.reduce((acc: any, current) => {
      // eslint-disable-next-line no-param-reassign
      acc[current.id] = current
      return acc
    }, {}) || {} as any,
  }), [data])

  const onSubmit = async (values: any) => {
    let result

    const body = {
      ...values,
      id: params.id,
      details: Object.keys(values.details).reduce((acc: any, key) => {
        // eslint-disable-next-line no-param-reassign
        acc[key] = {
          quantity: values.details[key].quantity,
          user_id: values.details[key].user_id || user!.id,
        }
        return acc
      }, {}),
    }

    if (params.id) {
      result = await edit(body)
    } else {
      result = await create(body)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  const handleTemplate = async (templateId: number) => {
    const template = await triggerTemplate(templateId)

    if ('data' in template) {
      formRef.current.setFieldValue('details', template.data?.details.reduce((acc: any, current) => {
        // eslint-disable-next-line no-param-reassign
        acc[current.id] = current
        return acc
      }, {}) || {})
    }
  }

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  return (
    <div>
      <AllowedTo roles={[UserRoles.Mechanic]}>
        <div>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
            type="link"
          >
            {t('common.goBack')}
          </Button>
        </div>
      </AllowedTo>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <AllowedTo roles={[UserRoles.Admin, UserRoles.ServiceManager]}>
                  <Col xs={24} md={12}>
                    <div style={{ display: 'flex' }}>
                      <SelectFormik
                        width="calc(100% - 40px)"
                        name="client_id"
                        options={clientsSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                        placeholder={t('tasks.columns.client') ?? ''}
                      />
                      <Button
                        size="large"
                        onClick={() => {
                          setIsCreate(true)
                          setCreateType('customer')
                        }}
                      >
                        +
                      </Button>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <SelectFormik
                      name="work_type_id"
                      options={workTypes?.map((item) => ({ value: item.id, label: item.name })) || []}
                      placeholder={t('tasks.columns.workType') ?? ''}
                      showSearch
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FieldFormik name="name" placeholder={t('tasks.columns.name') ?? ''} />
                  </Col>
                  <Col xs={24} md={12}>
                    <div style={{ display: 'flex' }}>
                      <SelectFormik
                        width="calc(100% - 40px)"
                        name="car_id"
                        options={carsSelect?.map((car) => ({ ...car, value: car.id, label: car.name })) || []}
                        placeholder={t('tasks.columns.car') ?? ''}
                        showSearch
                        onChange={(_value, current) => {
                          setFieldValue('client_id', current.owner_id)
                        }}
                      />
                      <Button
                        size="large"
                        onClick={() => {
                          setIsCreate(true)
                          setCreateType('car')
                        }}
                      >
                        +
                      </Button>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <DatePickerFormik
                      name="planned_date_start"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      placeholder={t('tasks.columns.plannedDateStart') ?? ''}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <DatePickerFormik
                      name="planned_date_end"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      placeholder={t('tasks.columns.plannedDateEnd') ?? ''}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <SelectFormik
                      name="worker_ids"
                      options={workers?.map((item) => ({ value: item.id, label: item.name })) || []}
                      placeholder={t('tasks.columns.workers') ?? ''}
                      isMultiple
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <SelectFormik
                      name="template_id"
                      options={templates?.map((item) => ({ value: item.id, label: item.name })) || []}
                      placeholder={t('tasks.columns.template') ?? ''}
                      onChange={(value) => {
                        handleTemplate(+value)
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <FieldFormik
                      disabled
                      name="place"
                      placeholder={t('tasks.columns.place') ?? ''}
                    />
                  </Col>
                  <Col span={24}>
                    <AddressFormik
                      name="place"
                      placeholder={t('tasks.columns.place') ?? ''}
                    />
                  </Col>
                  <Col span={24}>
                    <FieldFormik
                      name="description"
                      placeholder={t('tasks.columns.description') ?? ''}
                      type="textarea"
                      autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                  </Col>
                </AllowedTo>
                <Col span={24}>
                  <SelectFormik
                    name="detailsSelectTmp"
                    options={details?.filter((item) => !Object.keys(values.details).includes(item.id.toString())).map((item) => ({
                      ...item, value: item.id, label: `${item.name} ${item.serial_number}`,
                    })) || []}
                    onChange={(value: any, current) => {
                      setFieldValue('detailsSelectTmp', [])
                      setFieldValue('details', {
                        ...values.details,
                        [current[0].id]: {
                          ...current[0],
                          quantity: 1,
                        },
                      })
                    }}
                    isMultiple
                    placeholder={t('tasks.columns.details') ?? ''}
                  />
                </Col>
                <Col span={24}>
                  <List
                    bordered
                    header={(
                      <Row gutter={[20, 0]}>
                        <Col span={8}>{t('details.columns.name')}</Col>
                        <Col span={8}>{t('details.columns.serialNumber')}</Col>
                        <Col span={7}>{t('details.columns.quantity')}</Col>
                      </Row>
                    )}
                    dataSource={Object.values(values.details)}
                    renderItem={(item: any) => (
                      <List.Item>
                        <Row style={{ width: '100%' }} gutter={[20, 0]}>
                          <Col span={8}>{item.name}</Col>
                          <Col span={8}>{item.serial_number}</Col>
                          <Col span={7}>
                            <FieldFormik
                              name={`details.${item.id}.quantity`}
                              placeholder="quantity"
                              size="middle"
                              noMarginBottom
                              type="number"
                            />
                          </Col>
                          <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <AllowedTo roles={[UserRoles.Admin, UserRoles.ServiceManager]}>
                              <button
                                onClick={() => {
                                  const newDetails = values.details
                                  delete newDetails[item.id]
                                  setFieldValue('details', newDetails)
                                }}
                                type="button"
                              >
                                <DeleteOutlined />
                              </button>
                            </AllowedTo>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
              <Button
                htmlType="submit"
                type="primary"
                loading={isLoadingCreate || isLoadingEdit}
                style={{ marginTop: 16 }}
              >
                {params.id ? t('tasks.save') : t('tasks.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
      <Drawer
        title={
          (createType === 'car' && t('carCreate.title'))
          || (createType === 'worker' && t('usersCreate.title'))
          || (createType === 'customer' && t('customerCreate.title'))
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={1000}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {createType === 'car' && <CarsCreate isModal onClose={onCloseCreate} />}
        {createType === 'customer' && <CustomerCreate onClose={onCloseCreate} isModal successCallbackModal={onCloseCreate} />}
        {createType === 'worker' && <UsersCreate isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
    </div>
  )
}
