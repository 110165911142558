import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { Drawer } from 'antd'
import useQuery from '@src/hooks/useQuery'
import { isSelectionText } from '@src/lib/utils'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { CarCategoryType } from '@src/types/carCategories'
import { PageTable } from '@src/components/Table/PageTable'
import { Confirm } from '@src/components/Confirm/Confirm'
import cn from 'classnames'
import { CarCategoriesCreate } from './CarCategoriesCreate'

export const CarCategories = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = carCategoriesApi.useGetCarCategoriesListQuery(paramsFilters)
  const [deleteCarCategory] = carCategoriesApi.useDeleteCarCategoryMutation()

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const handleDelete = useCallback((record: CarCategoryType) => (
    deleteCarCategory(record.id!)
  ), [deleteCarCategory])

  const onCell = useCallback((record: CarCategoryType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/car-categories/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<CarCategoryType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '55px',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('carCategories.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '48px',
      render: (_, record) => (
        <Confirm
          title={t('deleteModal.deleteConfirmationTitle')}
          content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.category') })}
          okText={t('deleteModal.delete')}
          cancelText={t('deleteModal.cancel')}
          onOk={() => handleDelete(record)}
          className={cn('table_actions_item', 'table_actions_item__delete')}
        >
          <DeleteIcon />
        </Confirm>
      ),
    },
  ], [data?.meta.pageSize, handleDelete, onCell, paramsFilters.page, t])

  return (
    <div>
      <PageTable
        title={t('carCategories.title')!}
        onCreate={openDrawer}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={t('carCategories.createTitle')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CarCategoriesCreate
          onClose={closeDrawer}
        />
      </Drawer>
    </div>
  )
}
