import { FC, ReactNode, useMemo, useCallback, Fragment } from 'react'
import { DatePicker, Input, Select, Switch } from 'antd'
import { TableFiltersType, FilterType } from '@src/types/tableFilters'
import { authApi } from '@src/store/services/auth-service'
import { ReactComponent as SuffixIcon } from '@src/assets/suffix_icon.svg'
import { ReactComponent as CalendarIcon } from '@src/assets/calendar_icon.svg'
import moment from 'moment'
import style from './filters.module.scss'

type FiltersProps = {
  filters: TableFiltersType
  onChange: (value: any) => void
  loading?: boolean
  children?: ReactNode
  values?: Record<string, any>
}

export const FiltersV2: FC<FiltersProps> = ({
  children, filters, onChange, loading, values,
}) => {
  const { data: user } = authApi.useGetMeQuery()

  const renderFilter = useCallback((filter: FilterType) => {
    switch (filter.type) {
      case 'input':
        return (
          <Input
            key={filter.dataIndex}
            defaultValue={values?.[filter.dataIndex]?.[filter.operator!]}
            type="text"
            onChange={(event) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: event.target.value,
                },
              })
            }}
            placeholder={filter.placeholder}
            style={{
              width: filter.width,
              fontSize: '12px',
              fontWeight: '500',
              fontFamily: 'Inter, sans-serif',
            }}
          />
        )
      case 'datePicker':
        return (
          <DatePicker
            key={filter.dataIndex}
            defaultValue={
              values?.[filter.dataIndex]?.[filter.operator!]
                ? moment(values[filter.dataIndex][filter.operator!])
                : undefined
            }
            placeholder={filter.placeholder}
            onChange={(e) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: e?.format(),
                },
              })
            }}
            className={filter.className}
            style={{ width: filter.width }}
          />
        )
      case 'rangePicker': {
        const startDate = values?.[filter.dataIndex]?.$gte
        const endDate = values?.[filter.dataIndex]?.$lte

        const defaultStartDate = startDate ? moment(startDate) : null
        const defaultEndDate = endDate ? moment(endDate) : null

        const defaultValue = defaultStartDate && defaultEndDate ? [defaultStartDate, defaultEndDate] : undefined

        return (
          <DatePicker.RangePicker
            key={filter.dataIndex}
            placeholder={filter.placeholder as [string, string]}
            onChange={(e) => {
              onChange({
                [filter.dataIndex]: {
                  $gte: e && e[0]?.format('YYYY-MM-DD'),
                  $lte: e && e[1]?.format('YYYY-MM-DD'),
                },
              })
            }}
            // @ts-ignore
            defaultValue={defaultValue}
            className={style.rangePicker}
            separator="—"
            suffixIcon={<CalendarIcon color="#919395" />}
            style={{
              width: filter.width || 'fit-content',
              height: 'auto',
            }}
            allowClear={!filter.notAllowClear}
          />
        )
      }
      case 'select':
        return (
          <Select
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            allowClear
            showSearch={filter.showSearch || true}
            onChange={(event) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: event,
                },
              })
            }}
            onSearch={(e) => filter.onSearch && filter.onSearch(e)}
            className={style.selectContainer}
            style={{ width: filter.width }}
            optionFilterProp="children"
            defaultValue={values?.[filter.dataIndex]?.[filter.operator!]}
            suffixIcon={<SuffixIcon />}
            dropdownStyle={filter.dropdownWidth ? { width: filter.dropdownWidth } : undefined}
          >
            {filter.options?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      case 'switch':
        return (
          <Fragment key={filter.dataIndex}>
            <div className={style.switch_title}>{filter.placeholder}</div>
            <Switch
              onChange={(event) => {
                onChange({
                  [filter.dataIndex]: {
                    [filter.operator!]: event,
                  },
                })
              }}
            />
          </Fragment>
        )
      default:
        return null
    }
  }, [onChange, values])

  const filtersComponents = useMemo(() =>
    filters.flatMap((filter) => {
      if (Array.isArray(filter)) {
        const nestedFilters = filter.filter(
          (nestedFilter) => !nestedFilter.roles || nestedFilter.roles.includes(user?.role!),
        )

        if (!nestedFilters.length) {
          return []
        }

        return (
          <div
            key={nestedFilters.map((nestedFilter) => nestedFilter.dataIndex).join()}
            className={style.nestedFilters}
          >
            {nestedFilters.map((nestedFilter) => renderFilter(nestedFilter))}
          </div>
        )
      }

      if (filter.roles?.length) {
        if (!user?.role || !filter.roles.includes(user.role)) {
          return []
        }
      }

      return renderFilter(filter)
    }), [filters, user?.role, renderFilter])

  return (
    <div className={style.filters}>
      {!loading && (
        <>
          {filtersComponents}
          {children}
        </>
      )}
    </div>
  )
}
