import { FC, ReactNode } from 'react'
import { Modal } from 'antd'
import { ReactComponent as CloseModalIcon } from '@src/assets/close-modal_icon.svg'
import style from './confirm.module.scss'

const { confirm, destroyAll } = Modal

type ConfirmProps = {
  children: ReactNode
  title: string
  content: string
  okText: string
  cancelText: string
  onOk: () => void
  className?: string
  okIcon?: ReactNode
};

export const Confirm: FC<ConfirmProps> = ({
  children,
  title,
  content,
  okText,
  cancelText,
  onOk,
  className,
  okIcon,
}) => {
  const onOpenModal = () => {
    confirm({
      className: style.deleteModal,
      title: (
        <div className={style.titleContainer}>
          <span className={style.title}>{title}</span>
          <CloseModalIcon className={style.closeIcon} onClick={() => destroyAll()} />
        </div>
      ),
      icon: null,
      content: <p className={style.modalText}>{content}</p>,
      okText,
      cancelText,
      okButtonProps: {
        className: style.modalButton,
        icon: okIcon,
      },
      cancelButtonProps: {
        className: style.modalButtonCancel,
      },
      centered: true,
      onOk,
    })
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={className}
      onClick={onOpenModal}
    >
      {children}
    </div>
  )
}
