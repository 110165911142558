import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { Car, CarStatus } from '@src/types/car'
import { addQuery } from '@src/lib/route'
import { carsApi } from '@src/store/services/cars-service'
import { isSelectionText } from '@src/lib/utils'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { Confirm } from '@src/components/Confirm/Confirm'
import cn from 'classnames'
import style from '../../../Pages/Cars/cars.module.scss'

export const CarsTable = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = carsApi.useGetCarsListQuery(paramsFilters)
  const [deleteCar] = carsApi.useDeleteCarMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: Car) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/cars/view/${record.id}`)
      }
    },
  }), [history])

  const handleDelete = useCallback((record: Car) => (
    deleteCar(record.id!)
  ), [deleteCar])

  const columns = useMemo<ColumnProps<Car>[]>(() => [
    {
      title: t('cars.columns.make'),
      dataIndex: 'make',
      key: 'make',
      onCell,
    },
    {
      title: t('cars.columns.model'),
      dataIndex: 'model',
      key: 'model',
      onCell,
    },
    {
      title: t('cars.columns.category'),
      dataIndex: 'category',
      render: (category) => category?.name,
      key: 'model',
      onCell,
    },
    {
      title: t('cars.columns.year'),
      dataIndex: 'year',
      key: 'year',
      onCell,
    },
    {
      title: t('cars.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
      onCell,
    },
    {
      title: t('cars.columns.engineHours'),
      dataIndex: 'engine_hours',
      key: 'engine_hours',
      onCell,
    },
    {
      title: t('cars.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div>
          {status === CarStatus.Available && (
            <div className={cn(style.status, style.status__free)}>
              {t('cars.columns.statusFree')}
            </div>
          )}
          {status === CarStatus.InRent && (
            <div className={cn(style.status, style.status__rent)}>
              {t('cars.columns.statusRent')}
            </div>
          )}
          {status === CarStatus.Service && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusRepair')}
            </div>
          )}
          {status === CarStatus.Sold && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusSold')}
            </div>
          )}
        </div>
      ),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '48px',
      fixed: 'right',
      render: (_, record) => (
        <Confirm
          title={t('deleteModal.deleteConfirmationTitle')}
          content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.car') })}
          okText={t('deleteModal.delete')}
          cancelText={t('deleteModal.cancel')}
          onOk={() => handleDelete(record)}
          className={cn('table_actions_item', 'table_actions_item__delete')}
        >
          <DeleteIcon />
        </Confirm>
      ),
    },
  ], [handleDelete, onCell, t])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
  }
}
