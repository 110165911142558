import { useFormikContext } from 'formik'
import moment from 'moment'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { transportationApi } from '@src/store/services/transportation-service'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'
import { companiesApi } from '@src/store/services/companies-service'

export const TransportationBillsDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: transportation } = transportationApi.useGetOneTransportationQuery(values.transportation_id)
  const { data: clientCompany } = companiesApi.useGetOneCompanyQuery(values.company_id, { skip: !values.company_id })
  usePrintableBlock('#printable-block')

  const billNumber = useMemo(() => (
    values.bill_number || `${moment(transportation?.created_at).format('YYYY-MM-DD')}/${transportation?.id}`
  ), [transportation, values.bill_number])

  const payBy = useMemo(() => {
    if (values.pay_by) {
      return moment(values.pay_by).format('YYYY-MM-DD')
    }
    return moment(transportation?.date).add(15, 'day').format('YYYY-MM-DD')
  }, [values, transportation])

  const price = useMemo(() => values.bill.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0), [values])

  const pvm = useMemo(() => price * 0.21, [price])

  const total = useMemo(() => price + pvm, [pvm, price])

  const cargoRecipient = useMemo(() => {
    if (transportation?.customer_name && transportation?.customer_surname) {
      return `${transportation?.customer_name} ${transportation?.customer_surname}`
    }

    return transportation?.cargo_recipient
  }, [transportation])

  return (
    <>
      <div className="transportation_bills_document" id="printable-block">
        <p style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {values.isCredit ? 'KREDITINĖ ' : ''}
          PVM SĄSKAITA-FAKTŪRA
        </p>
        <p style={{ textAlign: 'center' }}>
          Serija SEN Nr.
          {' '}
          {billNumber}
        </p>
        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tbody>
            <tr>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pardavėjas
                <br />
                <strong>UAB Senresa</strong>
                <br />
                Vilų g. 36A - 60, LT-93102, Neringa
                <br />
                Įmonės kodas 304865983
                <br />
                PVM mok.kodas LT100012516511
                <br />
                A/s LT597180300038467953
              </td>
              <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
                Pirkėjas
                <br />
                <strong>{clientCompany?.full_name}</strong>
                <br />
                {clientCompany?.address}
                <br />
                Įmonės kodas
                {' '}
                {clientCompany?.company_code}
                <br />
                PVM mok.kodas
                {' '}
                {clientCompany?.vat_number}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          Data:
          {' '}
          {moment(values.date_closed || transportation?.date).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          Apmokėti iki:
          {' '}
          {payBy}
        </p>

        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tbody>
            <tr>
              <td className="image-row" style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}>Nr.</td>
              <td className="image-row" style={{ width: '33.5%', fontWeight: 700 }}>Pavadinimas</td>
              <td className="image-row" style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}>Mato vnt.</td>
              <td className="image-row" style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}>Kiekis</td>
              <td className="image-row" style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}>Kaina EUR</td>
              <td className="image-row" style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}>Suma EUR</td>
            </tr>
            {values.bill.map((item: any, index: number) => (
              <tr>
                <td
                  className="image-row"
                  style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}
                >
                  {index + 1}
                  .
                </td>
                <td className="image-row" style={{ width: '33.5%', fontWeight: 400 }}>{item.name}</td>
                <td
                  className="image-row"
                  style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}
                >
                  {item.unit ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}
                >
                  {item.amount ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}
                >
                  {!!values.isCredit && '-'}
                  {item.value ?? ''}
                </td>
                <td
                  className="image-row"
                  style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}
                >
                  {!!values.isCredit && '-'}
                  {item.value * item.amount ?? ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table style={{ fontSize: 13, width: '100%' }}>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Iš viso:</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {price.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>PVM 21%</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {pvm.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
            <tr style={{ width: '100%' }}>
              <td style={{ width: '20%', color: '#ffffff' }}>.</td>
              <td style={{ width: '35%' }}>Bendra suma</td>
              <td style={{ width: '45%', textAlign: 'right' }}>
                {!!values.isCredit && '-'}
                {total.toFixed(2)}
                {' '}
                EUR
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text">
          Iš viso su PVM:
          {' '}
          {n2words(customRound(total), { lang: 'lt' })}
          {' '}
          {total.toFixed(2).split('.')[1]}
          ct.
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}>Sąskaitą išrašė:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '83%', display: 'block', marginLeft: '17%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>Sąskaitą gavo:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <div className="page-break" />
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {' '}
          Krovinio važtaraštis Nr.
          {' '}
          {moment(transportation?.date).format('YYYY-MM-DD')}
          {' / '}
          {transportation?.id}
        </p>
        <p className="text" style={{ textAlign: 'right' }}>
          Data:
          {' '}
          {moment(transportation?.date).format('YYYY-MM-DD')}
        </p>
        <p className="text" style={{ width: '100%' }}>
          <span style={{ width: '100%' }}>
            <span style={{ float: 'left', width: '11%' }}>Siuntėjas:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '89%', display: 'block', marginLeft: '11%' }}>
              <span>
                {transportation?.from?.name}
                {' , '}
                {transportation?.from?.company_code}
              </span>
            </span>
          </span>
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (pavadinimas, įmonės kodas arba vardas pavardė, asmens kodas)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            Pasikrovimo adresas:
            {' '}
            <span style={{ borderBottom: 'solid 1px #000000', width: '100%' }}>
              <span>{transportation?.address_from}</span>
            </span>
          </span>
        </p>
        <p className="text" />
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '10%' }}> Gavėjas:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '90%', display: 'block', marginLeft: '10%' }}>
              <span>
                {transportation?.to?.name}
                {' , '}
                {transportation?.to?.company_code}
              </span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (pavadinimas, įmonės kodas arba vardas pavardė, asmens kodas)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            Išsikrovimo adresas:
            {' '}
            <span style={{ borderBottom: 'solid 1px #000000', width: '100%' }}>
              <span>{transportation?.address_to}</span>
            </span>
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            Vežėjas:
            {' '}
            <span style={{ borderBottom: 'solid 1px #000000', width: '100%' }}>
              <span>UAB Senresa</span>
            </span>
          </span>
        </p>
        <br />
        <p className="text">
          Vilkiko/puspriekabes valst. nr.
          {' '}
          {transportation?.plate ?? 'LII776/SN766'}
        </p>
        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tbody>
            <tr>
              <td className="image-row" style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}>Nr.</td>
              <td className="image-row" style={{ width: '44%', fontWeight: 700 }}>Krovinio pavadinimas</td>
              <td className="image-row" style={{ width: '16%', fontWeight: 700, textAlign: 'center' }}>Mato vnt.</td>
              <td className="image-row" style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}>Kiekis</td>
              <td className="image-row" style={{ width: '25%', fontWeight: 700, textAlign: 'center' }}>Krovinio svoris</td>
            </tr>
            {JSON.parse(transportation?.cargo || '[]').map((item: any, index: number) => (
              <tr>
                <td className="image-row" style={{ width: '5%', textAlign: 'center' }}>{index + 1}</td>
                <td className="image-row" style={{ width: '44%' }}>{item.name}</td>
                <td className="image-row" style={{ width: '16%', textAlign: 'center' }}>{item.unit ?? ''}</td>
                <td className="image-row" style={{ width: '10%', textAlign: 'center' }}>{item.amount ?? ''}</td>
                <td className="image-row" style={{ width: '25%', textAlign: 'center' }}>{item.weight ?? ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}> Krovinį išdavė:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '83%', display: 'block', marginLeft: '17%' }}>
              {transportation?.address_to ? (
                <>
                  <span>{transportation?.address_to}</span>
                  {!!transportation.owner_signature_s3 && (
                    <img
                      src={transportation.owner_signature_s3}
                      style={{ width: 120 }}
                      alt=""
                    />
                  )}
                </>
              ) : (
                <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
              )}
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (pareigos, vardas, pavardė, parašas)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '23%' }}> Krovinį priemė vežti:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '77%', display: 'block', marginLeft: '23%' }}>
              {transportation?.cargo_deliverer ? (
                <>
                  <span>{transportation?.cargo_deliverer}</span>
                  {!!transportation?.owner_signature_s3 && (
                    <img
                      src={transportation.owner_signature_s3}
                      style={{ width: 120 }}
                      alt=""
                    />
                  )}
                </>
              ) : (
                <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
              )}
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (pareigos, vardas, pavardė, parašas)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '15%' }}> Krovinį gavo:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '85%', display: 'block', marginLeft: '15%' }}>
              {cargoRecipient ? (
                <>
                  <span>{cargoRecipient}</span>
                  {!!transportation?.signature_s3 && (
                  <img
                    src={transportation.signature_s3}
                    style={{ width: 120 }}
                    alt=""
                  />
                  )}
                </>
              ) : (
                <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
              )}
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (pareigos, vardas, pavardė, parašas)
          </span>
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
