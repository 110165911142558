import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import { Button, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { CustomerWorkerCreate } from '@src/components/CustomerWorkerCreate/CustomerWorkerCreate'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { CustomerWorkerType } from '@src/types/customerWorkers'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { isSelectionText } from '@src/lib/utils'
import { PageTable } from '@src/components/Table/PageTable'
import cn from 'classnames'
import { Confirm } from '../Confirm/Confirm'
import style from './customerWorkers.module.scss'

type CustomerWorkersProps = {
  companyId: number
  notHeader?: boolean
}

export const CustomerWorkers: FC<CustomerWorkersProps> = ({ companyId, notHeader }) => {
  const { t } = useTranslation()
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    filters: {
      company_id: {
        $eq: companyId,
      },
    },
  })
  const { data, isLoading } = customerWorkersApi.useListQuery(
    paramsFilters,
    { skip: !companyId },
  )
  const [onDelete] = customerWorkersApi.useDeleteWorkerMutation()
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [editId, setEditId] = useState<null | number>(null)

  const handleDelete = useCallback((record: CustomerWorkerType) => (
    onDelete(record.id!)
  ), [onDelete])

  const onCell = useCallback((record: CustomerWorkerType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setEditId(record.id!)
        setIsOpenCreate(true)
      }
    },
  }), [])

  const columns = useMemo<ColumnProps<CustomerWorkerType>[]>(() => [
    {
      title: t('customerWorkers.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('customerWorkers.columns.email'),
      dataIndex: 'email',
      key: 'email',
      onCell,
    },
    {
      title: t('customerWorkers.columns.phone'),
      dataIndex: 'phone',
      key: 'phone',
      onCell,
    },
    {
      title: t('customerWorkers.columns.position'),
      dataIndex: 'position',
      key: 'position',
      onCell,
    },
    {
      title: t('customerWorkers.columns.default'),
      dataIndex: 'default',
      align: 'center',
      key: 'default',
      render: (_, record) => (
        <Tag
          color={record.default ? 'green' : 'red'}
          className={style.status}
        >
          {t(`common.${!!record.default}`)}
        </Tag>
      ),
      onCell,
    },
    {
      dataIndex: 'actions',
      fixed: 'right',
      width: '48px',
      key: 'actions',
      render: (_, record) => (
        <Confirm
          title={t('deleteModal.deleteConfirmationTitle')}
          content={t('deleteModal.deleteConfirmation', { localizedType: t('deleteModal.worker') })}
          okText={t('deleteModal.delete')}
          cancelText={t('deleteModal.cancel')}
          onOk={() => handleDelete(record)}
          className={cn('table_actions_item', 'table_actions_item__delete')}
        >
          <DeleteIcon />
        </Confirm>
      ),
    },
  ], [handleDelete, onCell, t])

  const onCloseCreate = () => {
    setIsOpenCreate(false)
    setEditId(null)
  }

  return (
    <div>
      {!notHeader && (
        <div className={style.header}>
          <h3>{t('customerWorkers.title')}</h3>
          <Button type="primary" onClick={() => setIsOpenCreate(true)}>
            {t('customerWorkers.add')}
          </Button>
        </div>
      )}

      <PageTable
        setParamsFilters={setParamsFilters}
        isTableOnly
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <CustomerWorkerCreate isOpen={isOpenCreate} onClose={onCloseCreate} companyId={companyId} editId={editId} />
    </div>
  )
}
