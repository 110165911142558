import { FC, useCallback, useState, useEffect, useRef } from 'react'
import { Modal, Upload, Image } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'
import type { RcFile, UploadProps } from 'antd/es/upload'
import { ReactComponent as PlusIcon } from '@src/assets/plus_icon.svg'
import style from './UploadImages.module.scss'

type UploadImagesProps = {
  fileList: any[]
  setFileList?: (data: UploadFile[]) => void
  isUploadButton?: boolean
  onRemove?: (data: any) => void
  isPictureCard?: boolean
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = () => reject(new Error('Error converting file to base64'))
  })

export const UploadImages: FC<UploadImagesProps> = ({
  fileList,
  setFileList,
  isUploadButton = true,
  onRemove,
  isPictureCard = true,
}) => {
  const { t } = useTranslation()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const imagePreviewsRef = useRef<{ [key: string]: string }>({})

  useEffect(() => {
    const processNewFiles = async () => {
      const filesToProcess = fileList.filter(
        (file) => !imagePreviewsRef.current[file.uid] && file.originFileObj,
      )

      const newPreviews: { [key: string]: string } = await Promise.all(
        filesToProcess.map(async (file) => {
          const preview = await getBase64(file.originFileObj as RcFile)
          return { [file.uid]: preview }
        }),
      ).then((previews) => Object.assign({}, ...previews))

      imagePreviewsRef.current = {
        ...imagePreviewsRef.current,
        ...newPreviews,
      }
    }

    processNewFiles().catch((err) => console.error('Error processing images:', err))
  }, [fileList])

  const handlePreview = (file: UploadFile) => {
    if (isPictureCard) {
      setPreviewOpen(true)
      const index = fileList.findIndex((item) => item.uid === file.uid)
      setCurrentIndex(index)
    } else {
      window.open(file.url, '_blank')?.focus()
    }
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (setFileList) {
      setFileList(newFileList)
    }
  }

  const onGalleryFileRemove = useCallback(
    (data: any) => {
      const { confirm } = Modal
      return new Promise((resolve, reject) => {
        confirm({
          title: t('confirmDeleteFileTitle'),
          onOk: () => {
            resolve(true)
            if (onRemove) {
              onRemove(data)
            }
          },
          onCancel: () => {
            reject(new Error('File removal canceled'))
          },
        })
      })
    },
    [onRemove, t],
  )

  const uploadButton = (
    <div className={style.button}>
      <PlusIcon />
      <div className={style.buttonText}>{t('images.addImage')}</div>
    </div>
  )

  return (
    <div className={style.wrapper}>
      <Upload
        beforeUpload={() => false}
        listType={isPictureCard ? 'picture-card' : undefined}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        // @ts-ignore
        onRemove={onGalleryFileRemove}
        multiple
      >
        {isUploadButton && uploadButton}
      </Upload>

      <Image.PreviewGroup
        preview={{
          visible: previewOpen,
          onVisibleChange: (visible) => setPreviewOpen(visible),
          current: currentIndex,
          onChange: (index) => setCurrentIndex(index),
        }}
      >
        {fileList.map((file) => (
          <Image
            key={file.uid}
            src={imagePreviewsRef.current[file.uid] || file.url || file.preview}
            alt={file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)}
            style={{ display: 'none' }}
          />
        ))}
      </Image.PreviewGroup>
    </div>
  )
}
