import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, Row, Drawer, Divider } from 'antd'
import { Preloader } from '@src/components/Preloader/Preloader'
import { selectCarsIsLoadingCreate } from '@src/store/ducks/cars/selectors'
import { carAddImage } from '@src/store/ducks/cars/thunks'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { UploadFile } from 'antd/es/upload/interface'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { SwitchFormik } from '@src/components/SwitchFormik/SwitchFormik'
import { CustomerCreate } from '@src/Pages/CustomerCreate/CustomerCreate'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { CarStatus } from '@src/types/car'
import style from './carsCreate.module.scss'

type CarsCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
  onClose:() => void
}

export const CarsCreate: FC<CarsCreateProps> = ({ isModal, successCallbackModal, onClose }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectCarsIsLoadingCreate)
  const { data, isLoading: isLoadingGetOne } = carsApi.useGetOneCarQuery(params.id ? +params.id : 0, { skip: !params.id || isModal, refetchOnMountOrArgChange: true })
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: categories } = carCategoriesApi.useGetCarCategoriesForSelectQuery()
  const [createCar, { isLoading: isLoadingCreate }] = carsApi.useCreateCarMutation()
  const [editCar, { isLoading: isLoadingEdit }] = carsApi.useEditCarMutation()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'customer' | null>(null)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      make: Yup.string().required(t('form.errors.required') ?? ''),
      model: Yup.string().required(t('form.errors.required') ?? ''),
      year: Yup.string().required(t('form.errors.required') ?? ''),
      serial_number: Yup.string().required(t('form.errors.required') ?? ''),
      engine_hours: Yup.string().required(t('form.errors.required') ?? ''),
      car_category_id: Yup.string().required(t('form.errors.required') ?? ''),
      car_value: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    make: data?.make ?? '',
    model: data?.model ?? '',
    year: data?.year ?? '',
    serial_number: data?.serial_number ?? '',
    engine_hours: data?.engine_hours ?? '',
    comment: data?.comment ?? '',
    status: data?.status ?? CarStatus.Available,
    fileList: [] as UploadFile[],
    owner_id: data?.owner_id ?? '',
    images: data?.images || {},
    paid_once: data?.paid_once || false,
    car_category_id: data?.car_category_id ?? '',
    car_value: data?.car_value ?? '',
  }), [data])

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
    } else {
      onClose()
    }
  }, [successCallbackModal, onClose])

  const onSubmit = useCallback(async (values: any) => {
    let result

    const formData = new FormData()
    formData.append('make', values.make)
    formData.append('model', values.model)
    formData.append('year', values.year)
    formData.append('serial_number', values.serial_number)
    formData.append('engine_hours', values.engine_hours)
    formData.append('comment', values.comment)
    formData.append('status', values.status)
    formData.append('owner_id', values.owner_id)
    formData.append('car_category_id', values.car_category_id)
    formData.append('car_value', values.car_value)
    values.fileList.forEach((image: any) => {
      formData.append('images[]', image.originFileObj)
    })

    if (params.id && !isModal) {
      result = await editCar({
        id: +params.id,
        formData,
      })
    } else {
      result = await createCar(formData)
    }

    if ('data' in result) {
      await dispatch(carAddImage(values, params.id ? +params.id : result.data.id))
      successCallback()
    }
  }, [createCar, dispatch, editCar, isModal, params.id, successCallback])

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  return (
    <div className={style.container}>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[16, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik name="make" placeholder={t('carCreate.make') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="model" placeholder={t('carCreate.model') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="year" placeholder={t('carCreate.year') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="serial_number" placeholder={t('carCreate.serialNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="engine_hours" placeholder={t('carCreate.engineHours') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="car_value" placeholder={t('carCreate.price') ?? ''} />
                </Col>
                <Col xs={24} md={24}>
                  <div style={{ display: 'flex' }}>
                    <SelectFormik
                      name="owner_id"
                      showSearch
                      placeholder={t('carCreate.owner') ?? ''}
                      options={ownersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                    />
                    <Button
                      size="large"
                      style={{ marginTop: '20px', marginLeft: '8px' }}
                      onClick={() => {
                        setIsCreate(true)
                        setCreateType('customer')
                      }}
                    >
                      +
                    </Button>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="car_category_id"
                    showSearch
                    placeholder={t('carCreate.category') ?? ''}
                    options={categories?.map((item) => ({ label: item.name, value: item.id })) || []}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="status"
                    placeholder={t('carCreate.status') ?? ''}
                    options={Object.values(CarStatus).map((item) => ({
                      value: item,
                      label: t(`cars.statuses.${item}`),
                    }))}
                  />
                </Col>
                <Col span={24}>
                  <FieldFormik
                    name="comment"
                    type="textarea"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    placeholder={t('carCreate.comment') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SwitchFormik name="paid_once" placeholder={t('carCreate.paidOnce')} />
                </Col>
                <Divider />
              </Row>

              <div className={style.upload}>
                <p className={style.uploadLabel}>
                  {t('carCreate.images') ?? ''}
                </p>
                <UploadImages
                  fileList={values.fileList}
                  setFileList={(image) => { setFieldValue('fileList', image) }}
                />
              </div>
              <div className={style.buttonContainer}>
                <Button className={style.button} htmlType="submit" type="primary" loading={isLoading || isLoadingCreate || isLoadingEdit}>
                  {t('carCreate.submit') ?? ''}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Preloader>
      <Drawer
        title={t('customerCreate.title')}
        open={isCreate}
        onClose={onCloseCreate}
        width={554}
      >
        {createType === 'customer' && <CustomerCreate onClose={onCloseCreate} isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
    </div>
  )
}
