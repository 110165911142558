import {
  FC, useCallback, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, Divider, Row } from 'antd'
import { ColorPickerFormik } from '@src/components/ColorPicker/ColorPicker'
import { Preloader } from '@src/components/Preloader/Preloader'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import style from './ownerCreate.module.scss'
// import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'

type OwnerCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
  onClose: () => void
}

export const OwnerCreate: FC<OwnerCreateProps> = ({ isModal, successCallbackModal, onClose }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const [createOwner, { isLoading: isLoadingCreate }] = companiesApi.useCreateCompanyMutation()
  const [editOwner, { isLoading: isLoadingEdit }] = companiesApi.useEditCompanyMutation()
  const { data, isLoading } = companiesApi.useGetOneCompanyQuery(params.id ? +params.id : 0, { skip: !params.id || isModal })
  const [createWorker] = customerWorkersApi.useCreateMutation()
  const { data: companyForms } = companiesApi.useGetCompaniesFormQuery()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      form: Yup.string().required(t('form.errors.required') ?? ''),
      name: Yup.string().required(t('form.errors.required') ?? ''),
      company_code: Yup.string().required(t('form.errors.required') ?? ''),
      vat_number: Yup.string().required(t('form.errors.required') ?? ''),
      address: Yup.string().required(t('form.errors.required') ?? ''),
      account_number: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    form: data?.form ?? '',
    name: data?.name ?? '',
    company_code: data?.company_code ?? '',
    vat_number: data?.vat_number ?? '',
    address: data?.address ?? '',
    account_number: data?.account_number ?? '',
    color: data?.color ?? '',
    type: CompanyType.Owner,
  }), [data])

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
      onClose()
    } else {
      onClose()
    }
  }, [successCallbackModal, onClose])

  const onSubmit = useCallback(async (values: any) => {
    let ownerResult
    if (params.id && !isModal) {
      ownerResult = await editOwner({ ...values, id: +params.id })
    } else {
      ownerResult = await createOwner(values)
    }
    if ('data' in ownerResult) {
      const ownerId = ownerResult.data.id

      if (values.nameWorker || values.emailWorker || values.phoneWorker || values.positionWorker) {
        const workerPayload = {
          name: values.nameWorker,
          email: values.emailWorker,
          phone: values.phoneWorker,
          position: values.positionWorker,
          company_id: ownerId,
          default: false,
        }

        const workerResult = await createWorker(workerPayload)

        if ('data' in workerResult) {
          successCallback()
        }
      } else {
        successCallback()
      }
    }
  }, [createOwner, editOwner, createWorker, isModal, params.id, successCallback])

  return (
    <div className={style.wrapper}>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[8, 0]}>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="form"
                    placeholder={t('customerCreate.formOwnership') ?? ''}
                    options={companyForms?.forms.map((item) => ({
                      label: item,
                      value: item,
                    })) || []}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="name" placeholder={t('ownerCreate.name') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="vat_number" placeholder={t('ownerCreate.vatNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="company_code" placeholder={t('ownerCreate.code') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="account_number" placeholder={t('ownerCreate.accountNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="address" placeholder={t('ownerCreate.address') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <ColorPickerFormik
                    name="color"
                    placeholder={t('ownerCreate.color') ?? ''}
                    onChangeComplete={(color) => setFieldValue('color', color.toHexString())}
                  />
                </Col>
                {!params.id && !isModal && (
                  <>
                    <Divider />
                    <Col xs={24} md={24}>
                      <p className={style.workerTitle}>
                        {t('customerWorkers.add') ?? ''}
                      </p>
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik name="nameWorker" placeholder={t('customerWorkers.columns.name') ?? ''} />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik name="emailWorker" placeholder={t('customerWorkers.columns.email') ?? ''} />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik name="phoneWorker" placeholder={t('customerWorkers.columns.phone') ?? ''} />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik name="positionWorker" placeholder={t('customerWorkers.columns.position') ?? ''} />
                    </Col>
                  </>
                )}
              </Row>
              <div className={style.buttonContainer}>
                <Button
                  className={style.button}
                  htmlType="submit"
                  type="primary"
                  loading={isLoadingCreate || isLoadingEdit}
                >
                  {params.id && !isModal ? t('ownerCreate.save') : t('ownerCreate.submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
