import React, { useCallback } from 'react'
import type { MenuProps } from 'antd'
import { UserRoles } from '@src/types/users'
import { NotificationTitle } from '@src/components/NotificationTitle/NotificationTitle'
import {
  CrownOutlined,
  FileTextOutlined, HomeOutlined,
  PartitionOutlined,
  ProfileOutlined,
  TagsOutlined,
} from '@ant-design/icons'

import { ReactComponent as OwnerIcon } from '@src/assets/owner-icon.svg'
import { ReactComponent as CustomerIcon } from '@src/assets/customer_icon.svg'
import { ReactComponent as CarCategoryIcon } from '@src/assets/car-category_icon.svg'
import { ReactComponent as CarsIcon } from '@src/assets/cars_icon.svg'
import { ReactComponent as InventoriesIcon } from '@src/assets/inventories_icon.svg'
import { ReactComponent as RentIcon } from '@src/assets/rent_icon.svg'
import { ReactComponent as TransportationsIcon } from '@src/assets/transportation_icon.svg'
import { ReactComponent as BuisnessIcon } from '@src/assets/buisness_icon.svg'
import { ReactComponent as ServiceIcon } from '@src/assets/service_icon.svg'
import { ReactComponent as AllInvoiceIcon } from '@src/assets/all-invoice_icon.svg'
import { ReactComponent as AccountingIcon } from '@src/assets/accountong_icon.svg'
import { ReactComponent as StatisticsIcon } from '@src/assets/statistics_icon.svg'
import { ReactComponent as SettingsIcon } from '@src/assets/settings_icon.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { authApi } from '@src/store/services/auth-service'

type MenuItem = Required<MenuProps>['items'][number];

export const useMenuList = () => {
  const { t } = useTranslation()
  const { data: user } = authApi.useGetMeQuery()

  const getCategory = useCallback((
    label: React.ReactNode,
    icon: React.ReactNode,
    children: MenuItem[],
    roles?: UserRoles[],
    type?: 'group',
  ): MenuItem => {
    if (roles?.length && !roles.includes(user!.role)) {
      return null
    }

    return {
      icon,
      children,
      label,
      type,
    } as MenuItem
  }, [user])

  const getItem = useCallback((
    label: string,
    link: string,
    icon: React.ReactNode,
    roles?: UserRoles[],
  ): MenuItem => {
    if (roles?.length && !roles.includes(user!.role)) {
      return null
    }

    return {
      icon,
      key: link,
      label: <NavLink to={link}>{label}</NavLink>,
    } as MenuItem
  }, [user])

  switch (user?.role) {
    case UserRoles.Admin:
      return [
        getItem(t('menu.owners'), '/owners', <OwnerIcon />),
        getItem(t('menu.customers'), '/customers', <CustomerIcon />),
        getItem(t('menu.carCategories'), '/car-categories', <CarCategoryIcon />),
        getItem(t('menu.cars'), '/cars', <CarsIcon />),
        getItem(t('menu.inventories'), '/inventories', <InventoriesIcon />),
        getCategory(t('selectRole.rent'), <RentIcon />, [
          getItem(t('menu.agreements'), '/agreements', <RentIcon />),
          getItem(`${t('menu.agreementOrders')}`, '/agreement-orders', <RentIcon />),
          getItem(`${t('menu.agreementInvoices')}`, '/agreement-invoices', <RentIcon />),
        ]),
        getCategory(t('selectRole.transportation'), <TransportationsIcon />, [
          getItem(t('menu.transportation'), '/transportation', <TransportationsIcon />),
          getItem(t('menu.transportationBills'), '/transportation-bills', <TransportationsIcon />),
          getItem(t('menu.monthlyPayments'), '/monthly-payments', <TransportationsIcon />),
          getItem(t('menu.trucks'), '/trucks', <TransportationsIcon />),
        ]),
        getCategory(t('selectRole.business'), <BuisnessIcon />, [
          getItem(t('menu.debts'), '/debts', <BuisnessIcon />),
          getItem(t('menu.invoices'), '/invoices', <BuisnessIcon />),
        ]),
        getCategory(t('selectRole.service'), <ServiceIcon />, [
          getItem(t('menu.serviceTemplates'), '/service-templates', <ServiceIcon />),
          getItem(t('menu.tasks'), '/tasks', <ServiceIcon />),
          getItem(t('menu.stocks'), '/stocks', <ServiceIcon />),
          getItem(t('menu.details'), '/details', <ServiceIcon />),
          getItem(t('menu.detailsInvoices'), '/detail-invoice', <ServiceIcon />),
          getItem(t('menu.detailsOffers'), '/details-offers', <ServiceIcon />),
          getItem(t('menu.standardWork'), '/standard-work', <ServiceIcon />),
          getItem(t('menu.serviceInvoices'), '/service-invoices', <ServiceIcon />),
        ]),
        getItem(t('menu.allInvoices'), '/all-invoices', <AllInvoiceIcon />),
        getItem(t('menu.accounting'), '/accounting', <AccountingIcon />),
        getItem(t('menu.statistics'), '/statistics', <StatisticsIcon />),
        getCategory(t('menu.suppliers'), <OwnerIcon />, [
          getItem(t('menu.supplierCategories'), '/supplier-categories', <OwnerIcon />),
          getItem(t('menu.suppliers'), '/suppliers', <OwnerIcon />),
        ]),
        getCategory(t('selectRole.settings'), <SettingsIcon />, [
          getItem(t('menu.users'), '/users', <SettingsIcon />),
          getItem(t('menu.mailLogs'), '/mail-logs', <SettingsIcon />),
        ]),
      ]
    case UserRoles.Driver:
      return [
        getItem(t('menu.owners'), '/owners', <OwnerIcon />),
        getItem(t('menu.customers'), '/customers', <CustomerIcon />),
        getItem(t('menu.transportation'), '/transportation', <TransportationsIcon />),
      ]
    case UserRoles.SalesManager:
      return [
        getItem(t('menu.customers'), '/customers', <CustomerIcon />),
        getItem('', '/notification', <NotificationTitle />),
      ]
    case UserRoles.ServiceManager:
      return [
        getItem(t('menu.customers'), '/customers', <CustomerIcon />),
        getItem(t('menu.tasks'), '/tasks', <ProfileOutlined />),
        getItem(t('menu.details'), '/details', <PartitionOutlined />),
        getItem(t('menu.detailsInvoices'), '/detail-invoice', <FileTextOutlined />),
        getItem(t('menu.detailsOffers'), '/details-offers', <FileTextOutlined />),
        getItem(t('menu.serviceInvoices'), '/service-invoices', <FileTextOutlined />),
        getCategory(t('selectRole.settings'), <SettingsIcon />, [
          getItem(t('menu.serviceTemplates'), '/service-templates', <SettingsIcon />),
          getItem(t('menu.stocks'), '/stocks', <SettingsIcon />),
          getItem(t('menu.standardWork'), '/standard-work', <SettingsIcon />),
        ]),
      ]
    case UserRoles.Mechanic:
      return [
        getItem(t('menu.cars'), '/mechanic/cars', <CrownOutlined />),
        getItem(t('menu.tasks'), '/', <CrownOutlined />),
        getItem(t('menu.tasksArchive'), '/mechanic/tasks-archive', <TagsOutlined />),
        getItem(t('menu.myDetails'), '/mechanic/my-details', <TagsOutlined />),
        getItem(t('menu.detailTransfer'), '/mechanic/detail-transfer', <CarsIcon />),
        getItem(t('menu.getDetail'), '/mechanic/get-detail', <CarsIcon />),
      ]
    case UserRoles.StoreManager:
      return [
        getItem(t('menu.stocks'), '/stocks', <HomeOutlined />),
        getItem(t('menu.details'), '/details', <PartitionOutlined />),
        getItem(t('menu.detailsOffers'), '/details-offers', <FileTextOutlined />),
        getItem(t('menu.detailsInvoices'), '/detail-invoice', <FileTextOutlined />),
      ]
    default:
      return []
  }
}
