import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Col, Descriptions, Drawer, Row } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { carsApi } from '@src/store/services/cars-service'
import { Column } from '@ant-design/charts'
import { configColumn } from '@src/components/Statistics/config'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import { CarViewContent } from '@src/components/pages/cars/CarViewСontent'
import { ViewContentHeader } from '@src/components/ViewContentHeader/ViewContentHeader'
import { CarsCreate } from '../CarsCreate/CarsCreate'
import style from './carsView.module.scss'

export const CarsView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('agreementOrders')
  const { data, isLoading } = carsApi.useGetOneCarQuery(+params.id)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  return (
    <div>
      {data && (
        <ViewContentHeader
          onBackClick={() => history.push('/cars')}
        >
          {`${data?.make}/${data?.model}`}
        </ViewContentHeader>
      )}
      <h2 className={style.title}>{t('carCreate.info')}</h2>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={8}>
          <Card
            style={{ borderRadius: '16px' }}
            loading={isLoading}
          >
            {data && (
              <>
                <Descriptions
                  column={1}
                  items={[
                    { label: t('cars.columns.year'), children: data.year },
                    { label: t('cars.columns.serialNumber'), children: data.serial_number },
                    { label: t('cars.columns.ownerName'), children: data.owner?.name },
                    { label: t('cars.columns.engineHours'), children: data.engine_hours },
                    { label: t('cars.columns.createdAt'), children: moment(data.created_at).format('YYYY-MM-DD') },
                    { label: t('cars.columns.paidOnce'), children: data.paid_once ? t('common.true') : t('common.false') },
                    { label: t('cars.columns.fromService'), children: data.from_service ? t('common.true') : t('common.false') },
                  ]}
                />
                <Column
                  {...configColumn}
                  xField="month"
                  height={250}
                  isGroup={false}
                  seriesField=""
                  data={[
                    { month: '2023-03', value: 20000 },
                    { month: '2023-04', value: 48400 },
                    { month: '2023-05', value: 30000 },
                    { month: '2023-06', value: 20000 },
                    { month: '2023-07', value: 48400.67 },
                    { month: '2023-08', value: 20000 },
                    { month: '2023-09', value: 48400.67 },
                    { month: '2023-10', value: 20000 },
                    { month: '2023-11', value: 48400.67 },
                    { month: '2023-12', value: 20000 },
                    { month: '2024-01', value: 48400.67 },
                    { month: '2024-02', value: 20000 },
                  ]}
                />
              </>
            )}
            <Button
              icon={<EditIcon />}
              className={style.buttonEdit}
              htmlType="button"
              type="primary"
              onClick={openDrawer}
            >
              {t('carCreate.edit')}
            </Button>
          </Card>
        </Col>
        <Col xs={24} lg={16}>
          <Card
            className={style.carCardStyle}
            loading={isLoading}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              {
                key: 'agreementOrders',
                label: t('menu.orders'),
              },
              {
                key: 'agreementInvoices',
                label: t('menu.invoices'),
              },
              {
                key: 'gallery',
                label: t('menu.gallery'),
              },
              {
                key: 'engineHours',
                label: t('menu.engineHours'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {data && (
              <CarViewContent
                activeTabKey={activeTabKey}
                serialNumber={data.serial_number}
                images={data?.images}
                id={data.id}
              />
            )}
          </Card>
        </Col>
      </Row>
      <Drawer
        title={t('carCreate.editCar')}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        <CarsCreate onClose={closeDrawer} />
      </Drawer>
    </div>
  )
}
