import { FC, ReactNode, useEffect, useState } from 'react'
import { SitebarLeft } from '@src/components/SitebarLeft/SitebarLeft'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'
import style from './PageContainer.module.scss'

type PageContainerProps = {
  children: ReactNode
}

export const PageContainer: FC<PageContainerProps> = ({ children }) => {
  const location = useLocation<any>()
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  useEffect(() => {
    if (isOpenMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isOpenMenu])

  useEffect(() => {
    setIsOpenMenu(false)
  }, [location])

  return (
    <Layout>
      <SitebarLeft isOpenMenu={isOpenMenu} />
      <Layout>
        {/* <Header collapsed={collapsed} setCollapsed={setCollapsed} setIsOpenMenu={() => setIsOpenMenu(!isOpenMenu)} /> */}
        <Layout.Content className={style.main}>
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}
