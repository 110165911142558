import { useTranslation } from 'react-i18next'
import { suppliersApi } from '@src/store/services/suppliers-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Supplier } from '@src/types/suppliers'
import { Button, Card, Col, Drawer, Row } from 'antd'
import { ReactComponent as EditIcon } from '@src/assets/edit_icon.svg'
import style from '@src/Pages/CarCategories/carsCategory.module.scss'
import { SupplierCreate } from '@src/Pages/Suppliers/SupplierCreate'

export const SupplierView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const { data, isFetching: isLoadingGetOne } = suppliersApi.useGetOneSupplierQuery(+params.id)

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    record: null as Supplier | null,
  })

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <Card
            loading={isLoadingGetOne}
            style={{
              borderRadius: '16px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('suppliers.name')}:`}</span>
                  <span className={style.cardTextResult}>{data?.name}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('suppliers.email')}:`}</span>
                  <span className={style.cardTextResult}>{data?.email}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('suppliers.phone')}:`}</span>
                  <span className={style.cardTextResult}>{data?.phone}</span>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <span className={style.cardText}>{`${t('suppliers.categories')}:`}</span>
                  <span className={style.cardTextResult}>{data?.categories.map((item) => item.name).join(', ')}</span>
                </div>
              </div>
              <Button
                type="primary"
                icon={<EditIcon />}
                size="large"
                className={style.button}
                onClick={() => setDrawerConfig({
                  isOpen: true,
                  record: data || null,
                })}
              >
                {t('suppliers.edit')}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Drawer
        title={t('suppliers.editTitle')}
        open={drawerConfig.isOpen}
        onClose={() => setDrawerConfig({ isOpen: false, record: null })}
        width={554}
      >
        <SupplierCreate onClose={() => setDrawerConfig({ isOpen: false, record: null })} />
      </Drawer>
    </div>
  )
}
