import { RootState } from '@src/store/store'
import { AppStateType, initialState } from './reducer'

export const selectApp = (state: RootState): AppStateType => state.app || initialState

export const selectAppSendEmailType = (state: RootState) => selectApp(state).sendEmailType
export const selectAppSendEmailCompanyId = (state: RootState) => selectApp(state).sendEmailCompanyId
export const selectAppSendEmailId = (state: RootState) => selectApp(state).sendEmailId
export const selectAppSendEmailIsAccountant = (state: RootState) => selectApp(state).sendEmailIsAccountant
export const selectAppSendEmailDocumentName = (state: RootState) => selectApp(state).sendEmailDocumentName
