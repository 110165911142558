export enum CompanyType {
  Customer = 'customer',
  Owner = 'owner'
}

export type Company = {
  id: number
  account_number?: string
  address: string
  agreement_number: string
  color?: string
  company_code?: string
  name: string
  full_name: string
  form: string
  type: CompanyType
  vat_number: string
  created_at: string
  from_service: boolean
  agreements: CompanyAgreements[]
}

export type CompanyAgreements = {
  agreement_number: string
  customer_id: number
  customer_representative_id: number
  date: string
  id: number
  owner_id: number
  owner_representative_id: number
}

export type CompanyRepresentative = {
  name: string
  position: string
}

export type CompaniesForSelectResponse = Array<{
  id: number
  name: string
}>

export type CompanyForms = {
  forms: Array<string>
}
