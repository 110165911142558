import { FC, useState } from 'react'
import { Images } from '@src/components/ImagesPreview/Image'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { tasksApi } from '@src/store/services/tasks-service'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'

type TaskPhotoGalleryProps = {
  images: any
  readonly?: boolean
  taskId: number
}

export const TaskPhotoGallery: FC<TaskPhotoGalleryProps> = ({ images, readonly, taskId }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [fileList, setFileList] = useState<any>([])
  const [deleteImage] = tasksApi.useDeleteTaskImageMutation()
  const [storeFile] = tasksApi.useStoreTaskImageMutation()

  const handleDeleteImage = async (id?: number) => {
    if (id === undefined) {
      return
    }
    await deleteImage(id)
  }

  const handleUpload = async () => {
    await Promise.all(fileList.map((image: any) => {
      const formData = new FormData()
      formData.append('image', image.originFileObj)

      return storeFile({ formData, taskId })
    }))

    setFileList([])
    dispatch(tasksApi.util.invalidateTags(['Tasks']))
  }

  return (
    <div>
      <Images
        imageList={images}
        onImageDelete={handleDeleteImage}
      />
      {!readonly && (
        <>
          <UploadImages
            fileList={fileList}
            setFileList={(image) => {
              setFileList(image)
            }}
          />
          {!!fileList.length && (
            <Button
              type="primary"
              size="large"
              onClick={handleUpload}
            >
              upload
            </Button>
          )}
        </>
      )}
    </div>
  )
}
