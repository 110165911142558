import { useTranslation } from 'react-i18next'
import { suppliersApi } from '@src/store/services/suppliers-service'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useQuery from '@src/hooks/useQuery'
import { useHistory } from 'react-router-dom'
import { addQuery } from '@src/lib/route'
import { ColumnProps } from 'antd/lib/table'
import { Supplier, SupplierCategory } from '@src/types/suppliers'
import { PageTable } from '@src/components/Table/PageTable'
import { TableFiltersType } from '@src/types/tableFilters'
import { Drawer, Popconfirm } from 'antd'
import { isSelectionText } from '@src/lib/utils'
import style from '@src/Pages/Companies/companies.module.scss'
import cn from 'classnames'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { SupplierCreate } from '@src/Pages/Suppliers/SupplierCreate'

export const Suppliers = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data, isLoading } = suppliersApi.useGetSuppliersListQuery(paramsFilters)
  const [deleteSupplier, { isLoading: isLoadingDelete }] = suppliersApi.useDeleteSupplierMutation()
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    record: null as Supplier | null,
  })

  useEffect(() => {
    history.push({
      search: addQuery({
        ...paramsFilters,
        filters: JSON.stringify(paramsFilters.filters),
      }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: Supplier) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/suppliers/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Supplier>[]>(() => [
    {
      title: t('suppliers.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('suppliers.email'),
      dataIndex: 'email',
      key: 'email',
      onCell,
    },
    {
      title: t('suppliers.phone'),
      dataIndex: 'phone',
      key: 'phone',
      onCell,
    },
    {
      title: t('suppliers.categories'),
      dataIndex: 'categories',
      key: 'categories',
      render: (categories) => categories.map((item: SupplierCategory) => item.name).join(', '),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '48px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteSupplier(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn('table_actions_item', 'table_actions_item__delete')}
              disabled={isLoadingDelete}
            >
              <DeleteIcon />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [deleteSupplier, isLoadingDelete, onCell, t])

  const filtersList = useMemo<TableFiltersType>(() => [[
    {
      type: 'input',
      dataIndex: 'name',
      operator: '$contains',
      placeholder: t('suppliers.name'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'email',
      operator: '$contains',
      placeholder: t('suppliers.email'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'phone',
      operator: '$contains',
      placeholder: t('suppliers.phone'),
      width: '200px',
    },
  ]], [t])

  return (
    <div>
      <PageTable
        title={t('suppliers.title')!}
        filtersV2
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onCreate={() => setDrawerConfig({
          isOpen: true,
          record: null,
        })}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={t('suppliers.createTitle')}
        open={drawerConfig.isOpen}
        onClose={() => setDrawerConfig({ isOpen: false, record: null })}
        width={554}
      >
        <SupplierCreate onClose={() => setDrawerConfig({ isOpen: false, record: null })} />
      </Drawer>
    </div>
  )
}
