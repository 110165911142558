import { useFormikContext } from 'formik'
import { companiesApi } from '@src/store/services/companies-service'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'

export const AgreementsDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: customer } = companiesApi.useGetOneCompanyQuery(values.customer_id, { skip: !values.customer_id })
  const { data: owner } = companiesApi.useGetOneCompanyQuery(values.owner_id, { skip: !values.owner_id })
  const { data: customerRepresentative } = customerWorkersApi.useGetOneQuery(values.customer_representative_id, { skip: !values.customer_representative_id })
  const { data: ownerRepresentative } = customerWorkersApi.useGetOneQuery(values.owner_representative_id, { skip: !values.owner_representative_id })

  usePrintableBlock('#printable-block')

  return (
    <>
      <div className="agreement_document" id="printable-block">
        <div className="headline">
          <div className="font-bold" style={{ textAlign: 'center' }}>
            MECHANIZMŲ BEI ĮRANGOS NUOMOS SUTARTIS NR.:
            {' '}
            {values.agreement_number}
          </div>
          <p className="text" style={{ textAlign: 'center' }}>
            Bendroji dalis
          </p>
          <p className="text" style={{ textAlign: 'center' }}>
            {values.date?.format('YYYY-MM-DD')}
          </p>
        </div>
        <p className="text" style={{ marginTop: 30 }}>
          <span style={{ display: 'inline-block', width: 16 }} />
          Ši mechanizmų ir
          Irangos nuomos sutartis (toliau vadinama - &quot;Sutartis&quot;) sudaryta tarp
          {' '}
          <strong>
            {owner?.full_name}
          </strong>
          , įmonės kodas:
          {' '}
          {owner?.company_code}
          {' '}
          registruotos buveinės adresas
          {' '}
          {owner?.address}
          , kurią atsovauja (toliau vadinamas - &quot;Nuomotojas&quot;) ir
          {' '}
          <strong>
            {customer?.full_name}
          </strong>
          , įmonės kodas
          {' '}
          {customer?.company_code}
          , kurią atsovauja (toliau vadinamas -&quot;Nuomininkas&quot;);
        </p>
        <p className="font-bold">1. SUTARTIES OBJEKTAS</p>
        <p className="text">
          1.1. Šioje Sutartyje nustatomos sąlygos ir tvarka, kuria Nuomotojas
          išnuomoja Nuomininkui mechanizmus ir kitą statybinę įrangą (toliau vadinamus
          - &quot;Įranga&quot;), taip pat gali suteikti kitas papildomas paslaugas.
        </p>
        <p className="text">
          1.2. Specialiosios Įrangos nuomos sąlygos nustatomos atskiru susitarimu,
          tame tarpe Įrangos perdavimo - priėmimo aktuose (toliau vadinami &quot;Įrangos
          perdavimo aktas&quot;), kurie visi yra šios Sutarties neatskiriamais priedais.
        </p>
        <p className="font-bold">2. NUOMOS SANTYKIAI</p>
        <p className="text">
          <span style={{ display: 'inline-block', width: 16 }} />
          {' '}
          Nuomos sutartis
          konkretaus Įrangos vieneto ar Įrangos vienetų grupės atžvilgiu laikoma
          sudaryta, kai dėl Įrangos nuomos specialių sąlygų Šalys susitaria bei tai
          įformina atitinkamu šios Sutarties priedu.
        </p>
        <p className="font-bold">3. NUOMOS LAIKOTARPIS</p>
        <p className="text">
          3.1. Įranga Nuomininkui gali būti išnuomota tik konkrečiam Įrangos perdavimo
          akte nurodytam terminui, kuris negali būti trumpesnis kaip 1 (viena) diena
          ir kuris (Nuomos laikotarpis) pasibaigia Įrangos perdavimo akte nurodytos
          dienos 18 (aštuonioliktą) valandą, jei abi Šalys nesusitaria kitaip.
        </p>
        <p className="text">
          3.2. Nuomos laikotarpis (terminas) Įrangos atžvilgiu pradedamas skaičiuoti
          nuo
          {' '}
          {values.date?.format('YYYY-MM-DD')}
          {' '}
          ir skaičiuojamas iki Nuomos laikotarpio paskutinės dienos, nebent abi
          šalys susitaria kitaip ar Nuomininkas negrąžina Įrangos laiku ir Nuomos
          mokestis skaičiuojamas iki Įranga bus grąžinta pagal kitas šios Sutarties
          nuostatas.
        </p>
        <p className="text">
          3.3. Paslaugas pagal šią Sutartį Nuomotojas Nuomininkui teikia tik darbo
          dienomis, ir tik darbo metu nuo 9 iki 18 val..
        </p>
        <p className="font-bold">4. ĮRANGOS PERDAVIMAS, PRIĖMIMAS IR GRĄŽINIMAS </p>
        <p className="text">
          4.1. Nuomos laikotarpio pradžioje, Įrangos perdavimo dieną, bei pabaigoje,
          Įrangos grąžinimo dieną, surašomi Įrangos perdavimo bei grąžinimo aktai.
          Nuomininkui nedalyvaujant Įrangos grąžinime jis praranda teisę nesutikti su
          Nuomotojo pateiktu Įrangos grąžinimo aktu ar ginčyti jį.
        </p>
        <p className="text">
          4.2. Įrangos priėmimo - perdavimo akte nurodoma Įrangos būklė (techninė
          būklė) priėmimo bei perdavimo momentu, detaliai nurodant įrangos
          komplektaciją ir būtinai jos trūkumus (jei jų turi), moto valandų skaičius.
          Nuomotojas turi teisę nufotografuoti Įrangą ją perduodant bei atsiimant.
        </p>
        <p className="text">
          4.3. Šalys susitaria, kad Įrangos perdavimo Nuomininkui metu Įrangos būklė
          (technine būklė) abiejų Sutarties Šalių (Nuomotojo ir Nuomininko) turi būti
          nuodugniai patikrinta, tuo siekiant išvengti galimų ateities ginčų dėl
          perduotos Įrangos būklės (techninės būklės) jos perdavimo metu ir priėmimo
          perdavimo akto pasirašymu Nuomininkas sutinka, jog Įranga buvo perduota
          tokia kaip nurodyta šiame akte. Jei nėra nurodomi jokie defektai, tai
          reiškia, jog Įranga jokių defektų neturėjo jos perdavimo metu. Perduodamas
          Įrangą Nuomininkas taip pat pareiškia, kad jis supranta ir atitinkamai
          įsipareigoja dėti visas nuo jo valios priklausančias protingas pastangas
          Įrangos būklės (techninės būklės) jos perdavimo (Nuomininkui) metu
          patikrinimui bei atitikimo tokios rūšies technikai nustatytiems
          reikalavimams.
        </p>
        <p className="text">
          4.4. Jei Šalys nesusitarta kitaip, Įranga Nuomininko paimama bei grąžinama
          Nuomotojo buveinės adresu (grąžinimo atveju Įranga grąžinama į atitinkamą
          padalinį iš kurio ji buvo paimta ir Nuomotojo pristatyta).
        </p>
        <p className="text">
          4.5. Įrangos naudojimui reikalingu kuru ir pan. Nuomininkas pasirūpina savo
          sąskaita ir jėgomis. Tuo atveju, jei Įrangą Nuomotojas Nuomininkui perduoda
          su kuru, Nuomininkas Įrangą privalo grąžinti taip pat su atitinkamu Nuomos
          laikotarpio pradžioje jam perduoto kuro kiekiu arba atitinkamai sumokėti
          sunaudoto kuro kainą.
        </p>
        <p className="text">
          4.6. Nuomininko pageidavimu, už papildomą iš anksto sutartą mokestį,
          Nuomotojas gali pasirūpinti Įrangos pristatymu, surinkimu, išrinkimu ir kt.
        </p>
        <p className="text">
          4.7. Nuo Įrangos priėmimo-perdavimo iki grąžinimo Nuomotojas prisiima visą
          atsakomybę bei Įrangos žuvimo riziką.
        </p>
        <p className="text">
          4.8 Nuomininkas sutinka, kad technika po nuomos būtų patikrinta
          autorizuotame servise, jeigu nuomuotojas turi įtarimų, kad technika, nuomos
          metu, buvo sugadinta ar per daug nudėvėta. Nuomininkas sutinka, kad įmonės
          UAB „HML“ autorizuotas Hyundai ir kitos sunkiosios servisas yra tinkamas
          defektacijos akto sudarymui, pasibaigus nuomai.
        </p>
        <p className="font-bold">5 ŠALIŲ PAREIGOS</p>
        <p className="text">
          5.1.Šalia kitų šioje Sutartyje numatytų įsipareigojimų
          {' '}
          <strong>Nuomotojas įsipareigoja</strong>
          :
        </p>
        <p className="text">
          5.1.1. Užtikrinti, kad Nuomininkui perduodama Įranga jos perdavimo metu būtų
          techniškai tvarkinga, švari bei nurodytos komplektacijos. Atsižvelgiant į
          tai, kad nėra įmanoma užtikrinti, kad naudojama Įranga negestų, tai kur
          nurodyta aukščiau reiškia, kad Nuomotojas neįsipareigoja užtikrinti, kad
          Įranga nesuges nuomos laikotarpiu, tačiau šia Sutartimi užtikrina tai, kad
          Įranga bus techniškai tvarkinga ir tinkama naudoti pagal paskirtį jos
          perdavimo Nuomininkui metu.
        </p>
        <p className="text">
          5.1.2. Įrangos naudojimo metu ne dėl Nuomininko kaltės Įrangai sugedus
          Nuomotojas įsipareigoja per protingą ir tam reikalingą terminą, po
          atitinkamo Nuomininko pranešimo apie gedimą, ne dėl Nuomininko kaltės
          sugedusią Įrangą sutaisyti arba vietoje sugedusios Įrangos Nuomininkui jo
          pageidavimu pateikti analogišką pakaitinę Įrangą;
        </p>
        <p className="text">
          5.1.3. Nuomotojas Nuomininkui kartu su perduodama Įranga perduoda Įrangos
          naudojimo instrukciją, kurią Nuomininkas privalo Nuomotojui grąžinti
          grąžindamas Įrangą nurodytą priėmimo perdavimo akte. Nuomininkui raštu
          paprašius, suteikti Nuomininkui papildomą informaciją apie Įrangos naudojimo
          ypatumus. Nuomotojas šios Sutarties pagrindu neapmoko bei neįsipareigoja
          Nuomininko apmokyti dirbti su išnuomojama Įranga, išskyrus kai atitinkamą
          pareigą Nuomotojui apmokyti Nuomininką imperatyviai numato Lietuvos
          Respublikos įstatymai.
        </p>
        <p className="text">
          5.2. Šalia kitų šioje Sutartyje numatytų įsipareigojimų
          {' '}
          <strong>Nuomininkas įsipareigoja</strong>
          :
        </p>
        <p className="text">
          5.2.1. Naudoti Įrangą tik pagal tiesioginę jos paskirtį griežtai laikantis
          Įrangos eksploatacijos instrukcijoje nurodytų reikalavimų bei užtikrinant,
          kad Įrangą eksploatuojantys asmenys būtų blaivūs, neapsvaigę nuo narkotinių
          medžiagų, taip pat, kad jie Nuomininko ar Nuomininko pasamdytų trečiųjų
          asmenų būtų tinkamai apmokyti dirbti su Įranga bei susipažinę su Įrangos
          eksploatavimo reikalavimais. Nuomininkas privalo per visą Įrangos nuomos
          laikotarpi užtikrinti, kad būtų laikomasi Lietuvos Respublikos teises
          aktuose nustatytų darbo ir techninės saugos taisyklių , naudoti,
          prizižiurėti ir saugoti Įrangą turint būtiną profesionalų pasiruosimą,
          tinkamus igūdžius ir laikantis atitinkamų atidumo principų, taip pat savo
          lėšomis uztikrinti Įrangos darbui reikalingq energiją, eksploatacinius
          skysčius, kasdienę priežiūrą ir pan.
        </p>
        <p className="text">
          5.2.2. Įrangos eksploatavimo metu pastebėjus kokius nors Įrangos darbo
          sutrikimus, nedelsiant nutraukti bet kokius darbus su šia Įranga ir apie tai
          nedelsiant (ne vėliau kaip iki darbo dienos, kurią Įrangos trūkumui
          pastebėti pabaigos) pranešti Nuomotojui.
        </p>
        <p className="text">
          5.2.3. Prieš pradedant darbą su Įranga pilnai ir tinkamai susipažinti bei
          atitinkamai, kai tai reikalinga, praeinant atitinkamus kursus, išmokti
          dirbti Įranga. Jei su Įranga Nuomininkas dirbti leis tretiems asmenims, jis
          privalo įsitikinti, kad šie asmenys būtų tinkamai apmokyti dirbti su Įranga
          bei įsipareigoja tinkamai juos su pažindinti su Įrangos eksploatavimo
          instrukcijomis bei teisės aktais keliamais reikalavimais. Nuomininkas
          įsipareigoja užtikrinti, kad su Nuomotojo Nuomininkui išnuomota Įranga
          nedirbtų bei atitinkamos galimybės su ja dirbti neturėtų nei vienas asmuo,
          kuris nėra tinkamai apmokytas su ja dirbti ar neturi atitinkamo leidimo ar
          teisės su Įranga dirbti (kai toks leidimas yra privalomas) ar nėra
          supažindintas su Įrangos eksploatavimo instrukcijomis.
        </p>
        <p className="text">
          NUOMININKAS TAIP PAT PAREIŠKIA, KAD JIS SUPRANTA BEI SUTINKA, KAD
          NESUSIPAŽINUS AR NESUPAŽINDINUS ATITINKAMŲ ASMENŲ, KURIE DIRBS SU ĮRANGA, SU
          ĮRANGOS EKSPLOATAVIMO INSTRUKCIJOMIS BEI TEISĖS AKTUOSE NUSTATYTAIS
          REIKALAVIMAIS, SU ĮRANGA DIRBTI YRA GRIEŽTAI DRAUDŽIAMA:
        </p>
        <p className="text">
          5.2.4. Įrangos naudojimo metu griežtai laikytis saugos reikalavimų, neleisti
          dirbti asmeniui, negalinčiam ar neturinčiam teisės to daryti.
        </p>
        <p className="text">
          NUO ĮRANGOS PERDAVIMO AKTO PASIRAŠYMO DATOS NUOMININKAS PRIEŠ TREČIUOSIUS
          ASMENIS DĖL ĮRANGOS PADARYTOS ŽALOS ATSAKO ASMENISKAI, KAIP ĮRANGOS
          VALDYTOJAS. NUOMININKAS PRISIIMA PILNĄ ATSAKOMYBĘ DĖL ŽALOS TURTUI BEI
          ĮRANGAI, SAVO IR KITŲ SVEIKATAI IR GYVYBEI, KURIĄ GALI SUKELTI ĮRANGA.
        </p>
        <p className="text">
          5.2.5.Įrangos nekeisti, neardyti, netaisyti ir netobulinti.
        </p>
        <p className="text">
          5.2.6. Nuomos laikotarpiui pasibaigus, grąžinti Įrangą tokią, kokią priėmė.
          Įrangą sugadinus, padengti Nuomotojo patirtus nuostolius(LR CK 6.49 str.);
        </p>
        <p className="text">
          5.2.7. Prieš grąžinant Įrangą ją nuplauti, išvalyti bei grąžinti tokią,
          kokią priėmė, su visais komponentais ir įranga. Nuomotojas pasilieka teisę
          išstatyti atskirą sąskaitą faktūrą Nuomininkui už Įrangos išvalymą po jos
          grąžinimo, jeigu įranga nebuvo nuplauta ir išvalyta.
        </p>
        <p className="text">
          5.2.8. Jei Nuomininkas pablogina Įrangą ar Įranga pablogėja dėl Nuomininko
          kaltės, ar Įrangą jis praranda, Nuomininkas Nuomotojui privalo atlyginti
          visą Įrangos rinkos vertės dydžio nuostolį ir dėl to patirtus nuostolius.
          Įrangos pabloginimu laikomas bet koks Įrangos vertės ir/ar jos funkcionalumo
          sumažėjimas, nesąlygotas normalaus nusidėvėjimo, taip pat bet kokie kiti
          Įrangos trūkumai, neaptarti Įrangos perdavimo metu Nuomos laikotarpio
          pradžioje.
        </p>
        <p className="text">
          5.2.9. Kiekvienu atveju prieš perduodant Įrangą, Nuomininkas privalo
          nurodyti tikslią Įrangos naudojimo bei saugojimo vietą (darbų atlikimo
          objektą/adresą) bei privalo užtikrinti, kad viso Nuomos laikotarpio metu
          Įranga būtų naudojama bei atitinkamai saugoma išimtinai Nuomotojui
          nurodytoje vietoje (objekte/adresu). Nuomos laikotarpio metu Nuomotojas turi
          teisę tikrinti ar Nuomininkas laikosi šio punkto nuostatų bei ar tinkamai
          naudoja Įrangą. Nuomininkas Nuomotoją turi nedelsiant raštu informuoti apie
          bet kurių iš savo rekvizitų (adreso, telefono numerio ir pan.) pasikeitimą.
        </p>
        <p className="text">
          5.2.10. NUOMININKAS ATSAKO UZ ĮRANGOS PRARADIMĄ (TAME TARPE VAGYSTĘ) BEI
          SUNAIKINIMĄ AR ŽŪTĮ VISAIS ATVEJAIS. Apie Įrangos praradimą ar sunaikinimą
          Nuomotojas turi būti raštu informuotas per vieną darbo dieną. Apie Įrangos
          vagystę Nuomininkas tos pačios dienos metu raštu privalo informuoti
          atitinkamas teisėsaugos institucijas. Nuomotojui nedelsiant pateikiant
          atitinkamo kreipimosi i teisėsaugos institucijas kopiją.
        </p>
        <p className="text">
          5.2.11. Nuomininkas įsipareigoja pranešti apie bet kokį technikos draudiminį
          įvykį ar technikos apgadinimą nuomotojui per 12 valandų po to, kai atsitinka
          koks auto įvykis, draudiminis ar nedraudiminis įvykis, bet kokiu atveju,
          jeigu išsinuomota technika kaip nors apgadinama.
        </p>
        <p className="text">
          5.2.12. Nuomuojama technika turi būti prižiūrima pagal gamintojo nurodytas
          rekomendacijas. Kiekvieną įrangos darbo dieną visi tepimo taškai privalo
          būti tepami ir prižiūrimi. Nesilaikant šios sąlygos numatyta bauda
          500eur+PVM už kiekvieną dieną.
        </p>
        <p className="text">
          5.3. Nuomininkas sutinka, jog pavėlavus grąžinti Įrangą sutartyje numatytu
          laiku, nuomos sutartis galioja iki kol bus grąžinta Įranga, tuo metu
          skaičiuojamas nuomos mokestis ir Nuomininkui taikoma 200 Eurų + PVM bauda už
          kiekvieną pradelstą dieną.
        </p>
        <p className="text">
          5.4. Nuomininkas sutinka, jog grąžinus Įrangą nešvarią viduje ar išorėje
          nuomininkui taikoma ne mažesnė kaip 0 Eurų + PVM bauda , arba didesnė dydžio
          bauda, kuri atitiktų įrangos plovimo išlaidas, kurias patirtų nuomotojas,
          įskaičiuojant technikos transportavimo, į plovyklą, kaštus.
        </p>
        <p className="text">
          5.5. Nuomuotojas Įrangą draudžia savo lėšomis CASCO draudimu su ne didesne
          nei 500 Eurų frančize. Prieš priimdamas Įrangą Nuomininkas pateikia
          Nuomotojui draudėjo pasirašytą CASCO draudimo poliso kopiją. Draudimas
          privalo būti atnaujintas likus 3 dienoms iki poliso galiojimui pabaigos.
          Šios nuostatos pažeidimas sąlygoja galimą vienašalį Sutarties nutraukimą
          Nuomotojui to pageidaujant.
        </p>
        <p className="text">
          5.6. Nuomininkas civilinės atsakomybės draudimu, jeigu jis yra reikalingas,
          naudojant techniką, rūpinasi pats.
        </p>
        <p className="text">
          5.7. Nuomininkas įsipareigoja tinkamai vykdyti sutartis ir mokėti nuomos
          įmokas.
        </p>
        <p className="font-bold">6 NUOMOS MOKESTIS, MOKĖJIMAI BEI ATSISKAITYMAI </p>
        <p className="text">
          6.1. Nuomos mokestis skaičiuojamas bei mokamas už Nuomos laikotarpį nuo
          Įrangos perdavimo akto surašymo iki Įrangos grąžinimo Nuomotojui ar 5.2.10.
          punkte numatytais atvejais Įrangos vertės Nuomotojui sumokėjimo dienos (šią
          dieną įskaitant).
        </p>
        <p className="text">
          6.2. Jei Šalių konkrečiu atveju nėra susitarta kitaip, nuomos mokestis
          mokamas pagal Nuomotojo sudaromame kainoraštyje, galiojančiame atitinkamos
          Įrangos Nuomos laikotarpio pirmą dieną, nustatytus tarifus ir tvarką.
        </p>
        <p className="text">
          6.3. Specialiojoje šios sutarties dalyje numatytas atlygis už minimalų darbo
          valandų per mėnesį skaičių, atsiskaitant iki kito mėnesio 3 d. už praėjusį
          mėnesį, pagal išrašytą sąskaitą faktūrą. Nuomos mokestis už darbo valandas,
          viršijusias minimalų darbo valandų skaičių mokamas kartu su kito mėnesio
          įmoka vadovaujantis šio straipsnio nuostatomis.
        </p>
        <p className="text">
          6.4. Jei Įranga į Nuomininko nurodytą vietą buvo pristatyta Nuomotojo ir/ar
          jo sumontuota, su pirmąja Nuomos mokesčio sąskaita Nuomotojas. Nuomininkui
          taip pat pateikia sąskaitą už Įrangos pristatymą ir/ar sumontavimą.
        </p>
        <p className="text">
          6.5. Tuo atveju jei Sutartis nutraukiama ar pasibaigia kitu pagrindu ir
          Nuomotojas pats turi nuvykti pas Nuomininką ir atsiimti įrangą, Šalys
          susitaria, kad Nuomininkas tokiu atveju privalo sumokėti Nuomotojui jo
          patirtas Įrangos parsigabenimo i buveinę išlaidas bei su tuo susijusias
          papildomas Nuomotojo išlaidas.
        </p>
        <p className="text">
          6.6. Nuomos mokestis už Įrangos nuomą mokamas Sutarčiai galiojant ir (ar)
          jai pasibaigus ir tais atvejais, kai Įranga dėl Nuomininko kaltės sugenda ar
          bet kai kokiu kitu atveju ji Nuomininko nėra naudojama ir (ar) negali būti
          naudojama, pavyzdžiui, Įranga sugadinama, areštuojama ir (ar) kita ir dėl to
          atliekami atitinkami veiksmai, pavyzdžiui, organizuojamas remontas, vyksta
          draudiminė byla, ikiteisminis tyrimas ir kita. Jei Sutartis šiame punkte
          numatytu laikotarpiu yra pasibaigusi, nuomos mokestis mokamas iki tol, kol
          pasibaigia aplinkybės ribojančios galimybę naudoti Įrangą.
        </p>
        <div className="page-break" />
        <p className="font-bold">7 ATSAKOMYBĖ </p>
        <p className="text">
          7.1. Jeigu Nuomininkas laiku nesumoka pagal Sutartį priklausančių mokėti
          sumų, jis už kiekvieną uždelstą sumokėti kalendorinę dieną Nuomotojui moka
          0,06 (šešių šimtųjų) procentų laiku nesumokėtos sumos dydžio delspinigius.
        </p>
        <p className="text">
          7.2. NUOMOTOJAS NEATSAKO UZ ĮRANGOS GEDIMO SALYGOTĄ ŽALĄ IR/AR NUOSTOLIUS.
          Įvykus Įrangos gedimui, preziumuojama Nuomininko kaltė.
        </p>
        <p className="font-bold">8 SUTARTIES GALIOJIMAS</p>
        <p className="text">
          8.1. Sutartis įsigalioja jos Šalių pasirašymo dieną, mokėjimai pradedami
          skaičiuoti pasirašius turto priėmimo-perdavimo aktą.
        </p>
        <p className="text">
          8.2. Sutartis galioja iki pasibaigs jos terminas ir bus grąžinta Įranga,
          arba Sutartis bus nutraukta teisės aktuose ar Sutartyje numatytais
          pagrindais ir tvarka.
        </p>
        <p className="text">
          8.3. Sutarties pakeitimams, papildymams ir priedams taikoma rašytinė forma.
        </p>
        <p className="text">
          8.4. Šalys susitaria, kad ši Sutartis negali būti nutraukta anksčiau nei po
          dviejų metų nuo šios Sutarties sudarymo dienos. Nuomininkui, siekiant
          nutraukti arba nutrūkus Sutarčiai anksčiau nei sueis šiame Sutarties punkte
          numatytas terminas, Nuomininkas įsipareigoja apmokėti nuomos mokesčius ir
          visas kitas mokėtinas sumas, kurios būtų sumokėtos pagal šią Sutartį iki
          šiame Sutarties punkte nurodyto termino pabaigos. O Nuomotojui nutraukus
          Sutartį prieš terminą, jis įsipareigoja atlyginti visus Nuomininko dėl šio
          išankstinio Sutarties nutraukimo patirtus tiesioginius nuostolius.
        </p>
        <p className="text">
          8.5. Nuomotojas turi teisę šią Sutartį nutraukti vienašališkai jei yra
          bloginama Įrangos būklė, jos vertė, Įranga nėra naudojamasi pagal paskirtį
          ar esant kitam esminiam šios Sutarties pažeidimui, įspėjus Nuomininką prieš
          14 (keturiolika) dienų.
        </p>
        <p className="text">
          8.6. Nuomininkas turi teisę Sutartį nutraukti vienašališkai jei Nuomotojas
          nevykdo šia Sutartimi prisiimtų įsipareigojimų pranešdamas Nuomotojui prieš
          14 (keturiolika) dienų.
        </p>
        <p className="text">
          8.7. Šalys įsipareigoja tarpusavio santykiuose laikytis konfidencialumo:
          neatskleisti raštu, žodžiu ar kitokiu pavidalu tretiesiems asmenims jokios
          komercinės, dalykinės, finansinės informacijos, su kuria buvo supažindintos
          bendradarbiaudamos šios Sutarties pagrindu. Jeigu bet kuri Šalis pažeis
          konfidencialumo įsipareigojimus, šiuos įsipareigojimus pažeidusi Šalis
          įsipareigoja sumokėti kitai Šaliai, jai pareikalavus, 1 000 Eur (vieno
          tūkstančio eurų) dydžio baudą už kiekvieną tokį pažeidimą.
        </p>
        <p className="font-bold">9 INFORMACIJA IR KORESPONDENCIJA</p>
        <p className="text">
          9.1. Visi raštiški pranešimai laikomi galiojančiais, jeigu jie pristatyti
          Šalies atstovui pasirašytinai arba pasiunčiami Šalies adresu registruotu
          paštu, per kurjerį arba siunčiant elektroniniu paštu.
        </p>
        <p className="text">
          9.2. Registruotas laiškas registruotu paštu arba per kurjerį yra laikomas
          įteiktas nuo jo pasirašymo dienos, o jei registruoto laiško kita šalis
          neatsiima iš pašto per 30 (trisdešimties) kalendorinių dienų nuo įteikimo
          paštui dienos, nesvarbu dėl kokių priežasčių, laikoma, kad registruotas
          laiškas yra gautas, praėjus 30 (trisdešimties) kalendorinių dienų nuo ant
          voko esančioje pašto žymoje „gauta“ nurodytos datos.
        </p>
        <p className="text">
          9.3. Elektroninis laiškas laikomas gautu nuo dienos kai Šalis patvirtina
          gavusi el. laišką, o Šaliai nepatvirtinus el. laiško gavimo, po 3 (trijų)
          darbo dienų nuo laiško išsiuntimo dienos.
        </p>
        <p className="text">
          9.4. Šalys įsipareigoja ne vėliau kaip per 2 (dvi) darbo dienas viena kitai
          pranešti apie jų oficialius adreso ir kitus rekvizitų pakeitimus.
        </p>
        <p className="font-bold">10 SUTARTIES NUTRAUKIMAS IR GINČŲ SPRENDIMAS </p>
        <p className="text">
          10.1. Nuomotojas turi teisę šią Sutartį vienašališkai ne ginčo tvarka
          nutraukti apie nutraukimą Nuomininkui pranešdamas prieš 1 (vieną) darbo
          dieną.
        </p>
        <p className="text">
          10.2. Nuomininkas turi teisę Sutartį nutraukti jei Nuomotojas nevykdo šia
          Sutartimi prisiimtų įsipareigojimų pranešdamas Nuomotojui prieš 1 (vieną)
          darbo dieną.
        </p>
        <p className="text">
          10.3. Ginčai tarp Šalių sprendžiami derybų būdu. Neišsprendus ginčo, jis
          sprendžiamas teisės aktų numatyta tvarka Vilniaus rajono apylinkės teisme.
        </p>
        <div className="page-break" />
        <p className="font-bold">11 KITOS NUOSTATOS </p>
        <p className="text">
          11.1. Visos Sutarties sąlygos bei informacija susijusi su jos vykdymu yra
          laikomos konfidencialia.
        </p>
        <p className="text">
          11.2. Sutartis sudaryta dviem vienodą galią turinčiais egzemplioriais po
          vieną kiekvienai Šaliai. Pasirašydamas šią Sutartį Nuomininkas sutinka, kad
          Nuomotojas Lietuvos Respublikos asmenų duomenų teisinės apsaugos įstatymo
          nustatyta tvarka šios sutarties tinkamo vykdymo priežiūros bei užtikrinimo
          tikslais tvarkytų jo asmens duomenis.
        </p>
        <p className="font-bold">12 TECHNIKOS PRIEŽIŪROS AKTAS</p>
        <p className="text">
          <span style={{ display: 'inline-block', width: 16 }} />
          {' '}
          Technikos nuomos
          laikotarpiu, klientas vadovaujasi žemiau pateiktais technikos priežiūros
          nuostatais:
        </p>
        <p className="text">
          12.1. Reguliariai atlikti technikos tepimo taškų sutepimą.
        </p>
        <p className="text">
          12.2. Reguliariai patikrinti alyvų ir skysčių lygius, reikalui esant,
          papildyti.
        </p>
        <p className="text">
          12.3. Reguliariai valyti nuo purvo vikšrinės, ratinės technikos važiuoklę,
          esant reikalui atlikti jos plovimą, ypatingą dėmesį skiriant tam šaltuoju
          metų laiku.
        </p>
        <p className="text">
          12.4.Reguliariai patikrinti vikšrinės technikos vikšrų įtempimo stovį, esant
          poreikiui, atlikti vikšrų įtempimo reguliavimą.
        </p>
        <p className="text">
          12.5. Atlikti technikos plovimą kiekvieną dieną, jeigu technika dirba labai
          dulkėtoje agresyvioje aplinkoje (betono dulkės, trašos, druskos, durpės ir
          t.t.).
        </p>
        <p className="text">
          12.6. Reguliariai tikrinti technikos oro filtrų būklę, esant poreikiui,
          prapūsti filtrus suspaustu oru.
        </p>
        <p className="text">
          12.7. Reguliariai tikrinti technikos aušinimo radiatorių būklę, esant
          poreikiui, prapūsti radiatorius suspaustu oru.
        </p>
        <p className="text">
          12.8. Reguliariai tikrinti oro slėgį padangose, esant poreikiui,
          sureguliuoti slėgį iki nustatytos reikšmės.
        </p>
        <p className="text">
          12.9. Reguliariai valyti operatoriaus kabiną nuo susikaupusių dulkių ir
          purvo.
        </p>
        <p className="text">
          12.10. Pasibaigus nuomos laikotarpiui, prieš grąžinant techniką nuomotojui,
          ją nuplauti ir išvalyti
        </p>
        <p className="text">
          12.11. Nesilaikant šių reikalavimų, to pasekoje atsitikus gedimams,
          nuomininkas privalo atlyginti patirtą žalą.
        </p>
        <table className="signatures" style={{ marginTop: 100 }}>
          <tbody>
            <tr className="row">
              <td className="col" style={{ verticalAlign: 'top' }}>
                <p className="text">Nuomotojas:</p>
                <p className="text">
                  <strong>
                    {owner?.full_name}
                  </strong>
                  <br />
                  Į.K.
                  {' '}
                  {owner?.company_code}
                  <br />
                  PVM mokėjimo kodas:
                  {' '}
                  {owner?.vat_number}
                  <br />
                  {owner?.address}
                </p>
              </td>
              <td className="col" style={{ verticalAlign: 'top' }}>
                <p className="text">Nuomininkas:</p>
                <p className="text">
                  <strong>
                    {customer?.full_name}
                  </strong>
                  <br />
                  Į.K.
                  {' '}
                  {customer?.company_code}
                  <br />
                  {customer?.address}
                </p>
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <br />
                <br />
              </td>
              <td className="col">
                <br />
                <br />
              </td>
            </tr>
            <tr className="row">
              <td className="col">
                <span className="text" style={{ borderTop: '1px dotted #000000' }}>
                  {ownerRepresentative?.position}
                  {' '}
                  <span style={{ display: 'inline-block', width: 56 }} />
                  {' '}
                  {ownerRepresentative?.name}
                </span>
              </td>
              <td className="col">
                <span className="text" style={{ borderTop: '1px dotted #000000' }}>
                  {customerRepresentative?.position}
                  {' '}
                  <span style={{ display: 'inline-block', width: 56 }} />
                  {' '}
                  {customerRepresentative?.name}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
