import { User } from '@src/types/users'

export enum DetailUnitEnum {
  Kg = 'kg',
  L = 'l',
  Vnt = 'vnt'
}

export type DetailType = {
  id: number
  from_service: boolean
  name: string
  price: number
  serial_number: string
  serial_numbers: Array<{
    serial_number: string
  }>
  service_id: string
  unit: DetailUnitEnum
  vat: number
  vat_price: number
  count: number
  total_count: number
  storage_count: number
  stock_area_id: number
  images: Array<{
    id: number
    image_s3: string
    thumb_s3: string
  }>
  area: {
    id: number
    name: string
    stock:
      {
        address: string
        id: number
        name: string
      }
    stock_id: number
  }
  storages: [Storage]
}

export type DetailShortType = {
  id: number
  name: string
  serial_number: string
  price?: number
}

export type Storage = {
  count: number
  detail_id: number
  user: {
    id: number
    name: string
    surname: string
  }
  user_id: number
}

export type UserDetailType = {
  id: number
  serial_number: string
  name: string
  serial_numbers: Array<{
    serial_number: string
  }>
  storages: Storage[]
}

export const REQUEST_STATUSES = {
  Created: 'created',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Closed: 'closed',
} as const

export type DetailTransferRequest = {
  count: number
  created_at: string
  detail_id: number
  from_id: number
  id: number
  status: (typeof REQUEST_STATUSES)[keyof typeof REQUEST_STATUSES]
  to_id: number
  updated_at: string
  user_id: number
  from: User
  to: User
}

export type DetailOfferType = {
  id?: number;
  number: string;
  company_id: number | null;
  date: string;
  percentage?: number;
  details: Array<{
    id: number;
    pivot: {
      count: number;
      price: number;
    };
    price: number;
    name: string;
  }>;
}

export enum SendStatusDetailsOffersType {
  created = 'created',
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}
