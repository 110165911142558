import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SendEmailType } from '@src/types/mailLogs'

type SetSendEmailTypePayloadAction = PayloadAction<{
  type: SendEmailType | null
  companyId: number
  id: number
  isAccountant?: boolean
  documentName?: string
}>

export const initialState = {
  sendEmailType: null as SendEmailType | null,
  sendEmailCompanyId: 0,
  sendEmailId: 0,
  sendEmailIsAccountant: false,
  sendEmailDocumentName: '',
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSendEmail(state, { payload }: SetSendEmailTypePayloadAction) {
      state.sendEmailType = payload.type
      state.sendEmailCompanyId = payload.companyId
      state.sendEmailId = payload.id
      state.sendEmailIsAccountant = payload.isAccountant ?? false
      state.sendEmailDocumentName = payload.documentName ?? ''
    },
    closeSendEmail(state) {
      state.sendEmailType = null
      state.sendEmailCompanyId = 0
      state.sendEmailId = 0
      state.sendEmailIsAccountant = false
      state.sendEmailDocumentName = ''
    },
    logoutAC() {},
  },
})

export type AppStateType = typeof initialState

export const {
  setSendEmail,
  closeSendEmail,
  logoutAC,
} = appSlice.actions

export const appReducer = appSlice.reducer
