import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Drawer } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ReactComponent as DeleteIcon } from '@src/assets/delete_icon.svg'
import { TableFiltersType } from '@src/types/tableFilters'
import { UserRoles } from '@src/types/users'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { companiesApi } from '@src/store/services/companies-service'
import { Company, CompanyType } from '@src/types/company'
import { PageTable } from '@src/components/Table/PageTable'
import { Confirm } from '@src/components/Confirm/Confirm'
import { isSelectionText } from '@src/lib/utils'
import { OwnerCreate } from '../OwnerCreate/OwnerCreate'
import { CustomerCreate } from '../CustomerCreate/CustomerCreate'
import style from './companies.module.scss'

type CompaniesProps = {
  type: CompanyType
}

export const Companies: FC<CompaniesProps> = ({ type }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [deleteCompany] = companiesApi.useDeleteCompanyMutation()
  const [exportCompanies, { isLoading: isLoadingExport }] = companiesApi.useExportCompaniesMutation()

  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })

  const { data, isLoading } = companiesApi.useGetCompanyListQuery({
    page: pageQuery ? +pageQuery : 1,
    filters: {
      ...paramsFilters.filters,
      type: { $eq: type },
    },
  })

  const openDrawer = () => setIsDrawerVisible(true)
  const closeDrawer = () => setIsDrawerVisible(false)

  const handleDelete = useCallback((record: Company) => (
    deleteCompany(record.id!)
  ), [deleteCompany])

  useEffect(() => {
    history.push({
      search: addQuery({
        ...paramsFilters,
        filters: JSON.stringify(paramsFilters.filters),
      }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: Company) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/companies/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Company>[]>(() => [
    {
      title: t('companies.columns.name'),
      dataIndex: 'full_name',
      key: 'name',
      onCell,
    },
    {
      title: t('companies.columns.code'),
      dataIndex: 'company_code',
      key: 'company_code',
      onCell,
    },
    {
      title: t('companies.columns.taxCode'),
      dataIndex: 'vat_number',
      key: 'tax_code',
      onCell,
    },
    {
      title: t('companies.columns.address'),
      dataIndex: 'address',
      key: 'address',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: '48px',
      render: (_, record) => (
        <AllowedTo roles={[UserRoles.Admin]}>
          <div className={style.actions}>
            <Confirm
              title={t('deleteModal.deleteConfirmationTitle')}
              content={t('deleteModal.deleteConfirmation', {
                localizedType: type === CompanyType.Customer ? t('deleteModal.customer') : t('deleteModal.owner'),
              })}
              okText={t('deleteModal.delete')}
              cancelText={t('deleteModal.cancel')}
              onOk={() => handleDelete(record)}
              className={cn('table_actions_item', 'table_actions_item__delete')}
            >
              <DeleteIcon />
            </Confirm>
          </div>
        </AllowedTo>
      ),
    },
  ], [t, onCell, type, handleDelete])

  const filtersList = useMemo<TableFiltersType>(() => [
    [
      {
        type: 'input',
        dataIndex: 'name',
        operator: '$contains',
        placeholder: t('companies.columns.name'),
        width: '160px',
      },
      {
        type: 'input',
        dataIndex: 'company_code',
        operator: '$contains',
        placeholder: t('companies.columns.code'),
        width: '160px',
      },
      {
        type: 'input',
        dataIndex: 'tax_code',
        operator: '$contains',
        placeholder: t('companies.columns.taxCode'),
        width: '160px',
      },
      {
        type: 'input',
        dataIndex: 'adress',
        operator: '$contains',
        placeholder: t('companies.columns.address'),
        width: '160px',
      },
      {
        type: 'select',
        dataIndex: 'is_verified',
        placeholder: t('companies.columns.isVerified'),
        operator: '$eq',
        width: '111px',
        options: [
          {
            value: '0',
            label: t('common.false'),
          },
          {
            value: '1',
            label: t('common.true'),
          },
        ],
      },
      {
        type: 'select',
        dataIndex: 'is_liquidated',
        operator: '$eq',
        placeholder: t('companies.columns.isLiquidated'),
        width: '111px',
        options: [
          {
            value: '0',
            label: t('common.false'),
          },
          {
            value: '1',
            label: t('common.true'),
          },
        ],
      },
    ],
  ], [t])

  return (
    <div>
      <PageTable
        title={type === CompanyType.Customer ? t('companies.customersTitle')! : t('companies.ownersTitle')!}
        filtersV2
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onExport={exportCompanies}
        isLoadingExport={isLoadingExport}
        onCreate={openDrawer}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Drawer
        title={type === CompanyType.Customer ? t('customerCreate.title')! : t('ownerCreate.title')!}
        open={isDrawerVisible}
        onClose={closeDrawer}
        width={554}
      >
        {type === CompanyType.Customer ? <CustomerCreate onClose={closeDrawer} /> : <OwnerCreate onClose={closeDrawer} />}
      </Drawer>
    </div>

  )
}
