import { Route, Switch } from 'react-router-dom'
import { Companies } from '@src/Pages/Companies/Companies'
import { Cars } from '@src/Pages/Cars/Cars'
import { Login } from '@src/Pages/Login/Login'
import { PrivateRoute } from '@src/routes/PrivateRoute'
import { Page404 } from '@src/Pages/404/404'
import { Transportation } from '@src/Pages/Transportation/Transportation'
import { TransportationCreate } from '@src/Pages/TransportationCreate/TransportationCreate'
import { UserRoles } from '@src/types/users'
import { TransportationBills } from '@src/Pages/TransportationBills/TransportationBills'
import { TransportationBillsEdit } from '@src/Pages/TransportationBillsEdit/TransportationBillsEdit'
import { AgreementInvoicesEdit } from '@src/Pages/AgreementInvoicesEdit/AgreementInvoicesEdit'
import { Accounting } from '@src/Pages/Accounting/Accounting'
import { Statistics } from '@src/Pages/Statistics/Statistics'
import { Invoices } from '@src/Pages/Invoices/Invoices'
import { InvoicesCreate } from '@src/Pages/InvoicesCreate/InvoicesCreate'
import { AllInvoices } from '@src/Pages/AllInvoices/AllInvoices'
import { Debts } from '@src/Pages/Debts/Debts'
import { DebtsCreate } from '@src/Pages/DebtsCreate/DebtsCreate'
import { MonthlyPayments } from '@src/Pages/MonthlyPayments/MonthlyPayments'
import { MonthlyPaymentsCreate } from '@src/Pages/MonthlyPaymentsCreate/MonthlyPaymentsCreate'
import { MailLogs } from '@src/Pages/MailLogs/MailLogs'
import { StandardWork } from '@src/Pages/Service/StandardWork/StandardWork'
import { StandardWorkCreate } from '@src/Pages/Service/StandardWorkCreate/StandardWorkCreate'
import { CompanyType } from '@src/types/company'
import { CompaniesView } from '@src/Pages/CompaniesView/CompaniesView'
import { CarsView } from '@src/Pages/CarsView/CarsView'
import { Users } from '@src/Pages/Users/Users'
import { UsersCreate } from '@src/Pages/UsersCreate/UsersCreate'
import { Details } from '@src/Pages/Service/Details/Details'
import { DetailsCreate } from '@src/Pages/Service/Details/DetailsCreate'
import { TasksCreate } from '@src/Pages/Service/Tasks/TasksCreate'
import { Tasks } from '@src/Pages/Service/Tasks/Tasks'
import { Trucks } from '@src/Pages/Trucks/Trucks'
import { TrucksCreate } from '@src/Pages/Trucks/TrucksCreate'
import { ServiceTemplates } from '@src/Pages/Service/ServiceTemplates/ServiceTemplates'
import { ServiceTemplatesCreate } from '@src/Pages/Service/ServiceTemplates/ServiceTemplatesCreate'
import { CarCategories } from '@src/Pages/CarCategories/CarCategories'
import { CarCategoriesView } from '@src/Pages/CarCategories/CarCategoriesView'
import { TaskView } from '@src/Pages/Service/Tasks/TaskView'
import { ServiceInvoices } from '@src/Pages/Service/ServiceInvoices/ServiceInvoices'
import { ServiceInvoicesEdit } from '@src/Pages/Service/ServiceInvoices/ServiceInvoicesEdit'
import { CarsMechanic } from '@src/Pages/Test/CarsMechanic/CarsMechanic'
import { TasksArchive } from '@src/Pages/Test/TasksArchive/TasksArchive'
import { DetailTransfer } from '@src/Pages/Test/DetailTransfer/DetailTransfer'
import { CarsMechanicView } from '@src/Pages/Test/CarsMechanicView/CarsMechanicView'
import { MyDetails } from '@src/Pages/Test/MyDetails/MyDetails'
import { GetDetail } from '@src/Pages/Test/GetDetail/GetDetail'
import { TaskArchiveView } from '@src/Pages/Test/TasksArchive/TasksArchiveView/TaskArchiveView'
import { Home } from '@src/Pages/Home/Home'
import { Stocks } from '@src/Pages/Stocks/Stocks'
import { StocksCreate } from '@src/Pages/Stocks/StocksCreate'
import { StockAreas } from '@src/Pages/Stocks/StockAreas'
import { StockAreasCreate } from '@src/Pages/Stocks/StockAreasCreate'
import { Inventories } from '@src/Pages/Inventories/Inventories'
import { InventoriesCreate } from '@src/Pages/Inventories/InventoriesCreate'
import { DetailsView } from '@src/Pages/Service/Details/DetailsView'
import { DetailsInvoices } from '@src/Pages/Service/DetailsInvoices/DetailsInvoices'
import { DetailsInvoicesCreate } from '@src/Pages/Service/DetailsInvoices/DetailsInvoicesCreate'
import { Notification } from '@src/Pages/Notification/Notification'
import { Agreements } from '@src/Pages/Agreements/Agreements'
import { AgreementsCreate } from '@src/Pages/Agreements/AgreementsCreate'
import { AgreementOrders } from '@src/Pages/AgreementOrders/AgreementOrders'
import { AgreementOrderCreate } from '@src/Pages/AgreementOrders/AgreementOrderCreate'
import { DetailsOffersCreate } from '@src/Pages/Service/DetaisOffers/DetailsOffersCreate'
import { DetailsOffers } from '@src/Pages/Service/DetaisOffers/DetailsOffers'
import { AgreementInvoices } from '@src/Pages/AgreementInvoices/AgreementInvoices'
import { SupplierCategories } from '@src/Pages/Suppliers/SupplierCategories'
import { SupplierCategoryView } from '@src/Pages/Suppliers/SupplierCategoryView'
import { Suppliers } from '@src/Pages/Suppliers/Suppliers'
import { SupplierView } from '@src/Pages/Suppliers/SupplierView'

export const Routes = () => (
  <Switch>
    <Route path="/" exact>
      <Home />
    </Route>
    <Route path="/login" exact>
      <Login />
    </Route>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver]} path="/owners" exact>
      <Companies type={CompanyType.Owner} key="owners" />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver, UserRoles.ServiceManager, UserRoles.SalesManager]} path="/customers" exact>
      <Companies type={CompanyType.Customer} key="customers" />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver, UserRoles.ServiceManager, UserRoles.SalesManager]} path="/companies/view/:id" exact>
      <CompaniesView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/cars" exact>
      <Cars />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/cars/view/:id" exact>
      <CarsView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreements" exact>
      <Agreements />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreements/create" exact>
      <AgreementsCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreements/edit/:id" exact>
      <AgreementsCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreement-orders" exact>
      <AgreementOrders />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreement-orders/create" exact>
      <AgreementOrderCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreement-orders/edit/:id" exact>
      <AgreementOrderCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver]} path="/transportation" exact>
      <Transportation />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver]} path="/transportation/create" exact>
      <TransportationCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Driver]} path="/transportation/edit/:id" exact>
      <TransportationCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/transportation-bills" exact>
      <TransportationBills />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/transportation-bills/edit/:id" exact>
      <TransportationBillsEdit />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/agreement-invoices/edit/:id" exact>
      <AgreementInvoicesEdit />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/agreement-invoices" exact>
      <AgreementInvoices />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/accounting" exact>
      <Accounting />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/statistics" exact>
      <Statistics />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/invoices" exact>
      <Invoices />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/invoices/create" exact>
      <InvoicesCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/invoices/edit/:id" exact>
      <InvoicesCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/all-invoices" exact>
      <AllInvoices />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/debts" exact>
      <Debts />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/debts/create" exact>
      <DebtsCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/debts/edit/:id" exact>
      <DebtsCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/monthly-payments" exact>
      <MonthlyPayments />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/monthly-payments/create" exact>
      <MonthlyPaymentsCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/monthly-payments/edit/:id" exact>
      <MonthlyPaymentsCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/mail-logs" exact>
      <MailLogs />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/standard-work" exact>
      <StandardWork />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/standard-work/edit/:id" exact>
      <StandardWorkCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/users" exact>
      <Users />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/users/create" exact>
      <UsersCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/users/edit/:id" exact>
      <UsersCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details" exact>
      <Details />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details/view/:id" exact>
      <DetailsView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details/create" exact>
      <DetailsCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details/edit/:id" exact>
      <DetailsCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details-offers" exact>
      <DetailsOffers />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details-offers/create" exact>
      <DetailsOffersCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager, UserRoles.StoreManager]} path="/details-offers/edit/:id" exact>
      <DetailsOffersCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/tasks" exact>
      <Tasks />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Mechanic, UserRoles.ServiceManager]} path="/tasks/view/:id" exact>
      <TaskView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Mechanic, UserRoles.ServiceManager]} path="/tasks/view-short/:id" exact>
      <TaskView readonly />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/tasks/create" exact>
      <TasksCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.Mechanic, UserRoles.ServiceManager]} path="/tasks/edit/:id" exact>
      <TasksCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/trucks" exact>
      <Trucks />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/trucks/create" exact>
      <TrucksCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/trucks/edit/:id" exact>
      <TrucksCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/service-templates" exact>
      <ServiceTemplates />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/service-templates/create" exact>
      <ServiceTemplatesCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/service-templates/edit/:id" exact>
      <ServiceTemplatesCreate />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin]} path="/car-categories" exact>
      <CarCategories />
    </PrivateRoute>
    {/* <PrivateRoute roles={[UserRoles.Admin]} path="/car-categories/create" exact>
      <CarCategoriesCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin]} path="/car-categories/edit/:id" exact>
      <CarCategoriesCreate />
    </PrivateRoute> */}
    <PrivateRoute roles={[UserRoles.Admin]} path="/car-categories/view/:id" exact>
      <CarCategoriesView />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/service-invoices" exact>
      <ServiceInvoices />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.ServiceManager]} path="/service-invoices/edit/:id" exact>
      <ServiceInvoicesEdit />
    </PrivateRoute>

    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/cars" exact>
      <CarsMechanic />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/cars/:id" exact>
      <CarsMechanicView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/tasks-archive" exact>
      <TasksArchive />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/tasks-archive/view/:id" exact>
      <TaskArchiveView />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/detail-transfer" exact>
      <DetailTransfer />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Mechanic]} path="/mechanic/my-details" exact>
      <MyDetails />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.StoreManager, UserRoles.ServiceManager]} path="/detail-invoice" exact>
      <DetailsInvoices />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.StoreManager, UserRoles.ServiceManager]} path="/details-invoices/create" exact>
      <DetailsInvoicesCreate />
    </PrivateRoute>
    <PrivateRoute roles={[UserRoles.Admin, UserRoles.StoreManager, UserRoles.ServiceManager]} path="/details-invoices/edit/:id" exact>
      <DetailsInvoicesCreate />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.Mechanic, UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/mechanic/get-detail"
      exact
    >
      <GetDetail />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.Mechanic, UserRoles.ServiceManager, UserRoles.StoreManager]}
      path="/mechanic/get-my-detail"
      exact
    >
      <GetDetail getMy />
    </PrivateRoute>

    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stocks"
      exact
    >
      <Stocks />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stocks/create"
      exact
    >
      <StocksCreate />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stocks/edit/:id"
      exact
    >
      <StocksCreate />
    </PrivateRoute>

    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stock-areas/:stock"
      exact
    >
      <StockAreas />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stock-areas/:stock/create"
      exact
    >
      <StockAreasCreate />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/stock-areas/:stock/edit/:id"
      exact
    >
      <StockAreasCreate />
    </PrivateRoute>

    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/inventories"
      exact
    >
      <Inventories />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/inventories/create"
      exact
    >
      <InventoriesCreate />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.ServiceManager, UserRoles.StoreManager, UserRoles.Admin]}
      path="/inventories/edit/:id"
      exact
    >
      <InventoriesCreate />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.Admin, UserRoles.SalesManager]}
      path="/notification"
      exact
    >
      <Notification />
    </PrivateRoute>

    <PrivateRoute
      roles={[UserRoles.Admin]}
      path="/supplier-categories"
      exact
    >
      <SupplierCategories />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.Admin]}
      path="/supplier-categories/view/:id"
      exact
    >
      <SupplierCategoryView />
    </PrivateRoute>

    <PrivateRoute
      roles={[UserRoles.Admin]}
      path="/suppliers"
      exact
    >
      <Suppliers />
    </PrivateRoute>
    <PrivateRoute
      roles={[UserRoles.Admin]}
      path="/suppliers/view/:id"
      exact
    >
      <SupplierView />
    </PrivateRoute>

    <Route path="*">
      <Page404 />
    </Route>
  </Switch>
)
